var vm = vm || {};

vm.PdfViewer = function () {
  this.visible = ko.observable();
  this.title = ko.observable();

  // For iframe
  this.srcIframe = ko.observable();

  // For PDFJS
  this.usePdfjs = ko.observable();
  this.currentPage = ko.observable().extend({ notify: "always" });
  this.currentZoom = ko.observable(1.2);
  this.currentRotate = ko.observable(0);
  this.totalPages = ko.observable();
  this.pdf = null;
  this.pagePending = false;
  this.rendering = false;

  this.prevEnabled = ko.pureComputed(function () {
    return this.currentPage() > 1;
  }, this);
  this.nextEnabled = ko.pureComputed(function () {
    return this.currentPage() < this.totalPages();
  }, this);
  this.currentPageText = ko.pureComputed(function () {
    if (this.totalPages() === 0) return "";
    return "Sida " + this.currentPage() + " av " + this.totalPages();
  }, this);

  this.currentPage.subscribe(function () {
    if (this.rendering) {
      this.pagePending = true;
    } else {
      this.renderCurrentPage();
    }
  }, this);
};

utils.extend(
  vm.PdfViewer.prototype,
  (function () {
    var base64Marker = ";base64,",
      base64ToUint8 = function (data) {
        var base64Index = data.indexOf(base64Marker),
          base64 = data.substring(base64Index + base64Marker.length),
          raw = window.atob(base64),
          rawLength = raw.length,
          result = new Uint8Array(new ArrayBuffer(rawLength));
        for (var i = 0; i < rawLength; i++) {
          result[i] = raw.charCodeAt(i);
        }
        return result;
      },
      show = function (urlOrBase64, title) {
        var self = this;

        this.usePdfjs(true); //utils.isIOS || utils.isIE || utils.isEdge);
        this.title(title);
        this.visible(true);
        this.totalPages(undefined);

        if (this.usePdfjs()) {
          var base64Index = urlOrBase64.indexOf(base64Marker),
            src = base64Index >= 0 ? base64ToUint8(urlOrBase64) : urlOrBase64;
          PDFJS.getDocument(src)
            .then(function (pdf) {
              self.pdf = pdf;
              self.totalPages(self.pdf.numPages);
              self.currentPage(1);
            })
            .catch(function (error) {
              console.log(error);
              self.totalPages(0);
            });
          self.srcIframe(urlOrBase64);
        } else {
          self.srcIframe(urlOrBase64);
        }
      },
      download = function () {
        var a = window.document.createElement("a");
        a.href = this.srcIframe();
        a.download = this.title() || "file.pdf";
        a.click();
      },
      hide = function () {
        this.visible(false);
      },
      prev = function () {
        this.currentZoom(1.2);
        this.currentPage(this.currentPage() - 1);
      },
      next = function () {
        this.currentZoom(1.2);
        this.currentPage(this.currentPage() + 1);
      },
      zoomOut = function () {
        this.currentZoom(this.currentZoom() * 0.8);
        renderCurrentPage.call(this);
      },
      zoomIn = function () {
        this.currentZoom(this.currentZoom() * 1.25);
        renderCurrentPage.call(this);
      },
      rotate = function () {
        this.currentRotate(this.currentRotate() + 90);
        renderCurrentPage.call(this);
      },
      rotateBack = function () {
        this.currentRotate(this.currentRotate() - 90);
        renderCurrentPage.call(this);
      },
      renderCurrentPage = function () {
        this.rendering = true;
        var self = this,
          canvas = document.getElementById("pdfviewercanvas"),
          ctx = canvas.getContext("2d"),
          scale = this.currentZoom(),
          scaleFactor = 2;

        this.pdf.getPage(this.currentPage()).then(function (page) {
          var viewport = page.getViewport(scale * scaleFactor);
          if (viewport.height * viewport.width > 16777216) {
            scaleFactor = 1;
            viewport = page.getViewport(scale * scaleFactor);
          }
          canvas.height = viewport.height;
          canvas.width = viewport.width;
          canvas.style.height =
            Math.floor(viewport.height / scaleFactor) + "px";
          canvas.style.width = Math.floor(viewport.width / scaleFactor) + "px";

          var renderContext = {
            canvasContext: ctx,
            viewport: viewport,
          };
          var renderTask = page.render(renderContext);

          renderTask.promise.then(function () {
            self.rendering = false;
            if (self.pagePending) {
              renderCurrentPage.call(self);
              self.pagePending = false;
            }
          });
        });
      };

    return {
      show: show,
      hide: hide,
      prev: prev,
      next: next,
      zoomOut: zoomOut,
      zoomIn: zoomIn,
      rotate: rotate,
      rotateBack: rotateBack,
      download: download,
      renderCurrentPage: renderCurrentPage,
    };
  })()
);

vm.pdfViewer = new vm.PdfViewer();
