ko.validation.utils.propertiesWithValidationErrors = function (observable) {
    var errors = [];

    var checkForErrors = function (object, propChain) {
        propChain = propChain || "";
        for (var name in object) {
            if (object.hasOwnProperty(name)) {
                var value = object[name];
                if (value && value.isValid && !value.isValid()) {
                    errors.push({ property: propChain + "." + name, error: value.error() });
                }
                
                var unwrapped = ko.unwrap(value);
                if (unwrapped && (typeof unwrapped === "object" || Array.isArray(unwrapped))) {
                    checkForErrors(unwrapped, propChain + (Array.isArray(object) ? "[" + name + "]" : (propChain ? "." : "") + name));
                }
            }
        }
    }
    checkForErrors(ko.unwrap(observable));

    return errors;
}

// TODO: Does not work correctly
ko.utils.diffObservables = function (left, right) {
    var diffs = [];

    var diff = function (l, r, propChain) {
        propChain = propChain || "";
        for (var name in l) {
            if (l.hasOwnProperty(name) || r.hasOwnProperty(name)) {
                var diffsl = [];
                var diffsr = [];
                var vl = l ? l[name] : undefined; //ko.unwrap(l[name]);
                var vr = r ? r[name] : undefined; //ko.unwrap(r[name]);
                var tl = typeof vl;
                var tr = typeof vr;
                var al = Array.isArray(vl);
                var ar = Array.isArray(vr);

                if (l.hasOwnProperty(name) !== r.hasOwnProperty(name)) {
                    diffsl.push("hasprop=" + l.hasOwnProperty(name));
                    diffsr.push("hasprop=" + r.hasOwnProperty(name));
                }
                if (tl !== tr) {
                    diffsl.push("typeof=" + tl);
                    diffsr.push("typeof=" + tr);
                }
                if ((vl === null || tl !== "object") && (vr === null || tr !== "object") && !al && !ar && vl != vr) {
                    diffsl.push("value=" + vl);
                    diffsr.push("value=" + vr);
                }
                diffsl.length && diffs.push({ property: (propChain ? propChain + "." : "") + name, left: diffsl.join(", "), right: diffsr.join(", ") });

                if (vl && vr && ((typeof vl === "object" && typeof vr === "object") || (al && ar)))
                    diff(vl, vr, propChain + (Array.isArray(l) ? "[" + name + "]" : (propChain ? "." : "") + name));
            }
        }
    }
    diff(ko.toJS(left), ko.toJS(right));

    return diffs;
}

// Knockout binding handlers

// Shows/hides modal depending on condition. Focuses first or specified element when opened.
// Examples:
//    Show modal if 'isEditing' is true, first element receives focus:
//        data-bind="showModal: isEditing"
//    Show modal if 'isEditing' is true, first element receives focus:
//        data-bind="showModal: { if: isEditing }"
//    Element with id 'password' receives focus:
//        data-bind="showModal: { if: isEditing, focus: 'password' }"
//    Element with id 'password' receives focus:
//        data-bind="showModal: { if: isEditing, focus: { id: 'password' } }"
//    Element with id 'password' receives focus if 'isAdmin' is trueish, else first element receives focus:
//        data-bind="showModal: { if: isEditing, focus: { id: 'password', if: isAdmin } }"
ko.bindingHandlers.showModal = {
    init: function (element, valueAccessor) {
        $(element).on("shown.bs.modal", function () {
            var elementToFocus = undefined;

            var value = valueAccessor();
            // Nested observables possible, use toJS instead of unwrapObservable
            var unwrapped = ko.toJS(value);
            if (unwrapped.focus) {
                var id = unwrapped.focus.id || unwrapped.focus;
                var focusCondition = unwrapped.focus.if || unwrapped.focus.if === undefined;

                if (focusCondition) {
                    elementToFocus = $("#" + id);
                }
            }

            if (elementToFocus == undefined)
                elementToFocus = $("input, select, textarea, button", element).first();
            if (elementToFocus != undefined)
                elementToFocus.focus();
        });
    },
    update: function (element, valueAccessor) {
        var value = valueAccessor();
        var unwrapped = ko.toJS(value);
        var showCondition = typeof unwrapped !== "object" ? unwrapped : unwrapped.if;

        if (showCondition) {
            $(element).modal("show");
        }
        else {
            $(element).modal("hide");
        }
    }
};

// Overwrite knockout's options binding handler so all selects become 'chosen' selects
var originalKnockoutOptions = ko.bindingHandlers.options;

ko.bindingHandlers.options = {
    init: function (element, valueAccessor, allBindingsAccessor) {
        // Call original
        originalKnockoutOptions.init(element);
        // Make the select 'chosen' by using chosenOptions
        var allBindings = allBindingsAccessor();
        if (!allBindings.chosenOptions)
            ko.bindingHandlers.chosenOptions.init(element);
    },
    update: function (element, valueAccessor, allBindingsAccessor) {
        originalKnockoutOptions.update(element, valueAccessor, allBindingsAccessor);
        $(element).trigger("chosen:updated");
    }
};

ko.bindingHandlers.chosenOptions = {
    init: function (element, valueAccessor) {
        var options = valueAccessor && ko.unwrap(valueAccessor());
        options = options || {};
        // Unless specified in options, add width of 100%. Width will otherwise be 0 if select has no content at load time
        options.width = options.width || "100%";
        options.disable_search = typeof options.disable_search !== "undefined" ? options.disable_search : true;
        options.display_selected_options = typeof options.display_selected_options !== "undefined" ? options.display_selected_options : false;
        options.placeholder_text_single = options.placeholder_text_single || "Välj ett alternativ";
        options.placeholder_text_multiple = options.placeholder_text_multiple || "Välj en eller fler alternativ";
        options.no_results_text = options.no_results_text || "Inga resultat för";
        $(element).chosen(options);
    }
};

var originalKnockoutSelectedOptions = ko.bindingHandlers.selectedOptions;

ko.bindingHandlers.selectedOptions = {
    after: originalKnockoutSelectedOptions.after,
    init: function (element, valueAccessor, allBindings) {
        originalKnockoutSelectedOptions.init(element, valueAccessor, allBindings);
        // 'Subscribe' to selected items to update selected options texts
        if (ko.isObservable(valueAccessor())) {
            ko.computed({
                read: function () {
                    ko.toJSON(valueAccessor());
                    $(element).trigger("chosen:updated");
                },
                disposeWhenNodeIsRemoved: element
            });
        }
    },
    update: function (element, valueAccessor) {
        originalKnockoutSelectedOptions.update(element, valueAccessor);
        $(element).trigger("chosen:updated");
    }
};

ko.bindingHandlers.selectedValue = {
    after: ko.bindingHandlers.value.after,
    init: function (element, valueAccessor, allBindings) {
        ko.bindingHandlers.value.init(element, valueAccessor, allBindings);
        // 'Subscribe' to selected item to update selected option text
        if (ko.isObservable(valueAccessor())) {
            ko.computed({
                read: function () {
                    ko.toJSON(valueAccessor());
                    $(element).trigger("chosen:updated");
                },
                disposeWhenNodeIsRemoved: element
            });
        }
    }
}

// Override enable (and indirectly disable) to trigger chosen:updated event in case enable is on a chosen select
var originalKnockoutEnable = ko.bindingHandlers.enable;
ko.bindingHandlers.enable = {
    update: function (element, valueAccessor) {
        originalKnockoutEnable.update(element, valueAccessor);
        var value = ko.unwrap(valueAccessor());
        if (value)
            $(element).removeClass("disabled");
        else if (!$(element).hasClass("disabled"))
            $(element).addClass("disabled");
        $(element).trigger("chosen:updated");
    }
};

ko.bindingHandlers.hrefWithParams = {
    update: function (element, valueAccessor) {
        var value = ko.toJS(valueAccessor());
        var route = value.route;
        var params = value.params;

        route = utils.replaceRouteParams(route, params);

        $(element).attr("href", route);
    }
}

ko.bindingHandlers.toggle = {
    init: function (element, valueAccessor) {
        var value = valueAccessor();
        var first = true;
        ko.applyBindingAccessorsToNode(element, {
            click: function () {
                !first ? value(!value()) : first = false;
            }
        });
    }
};

ko.bindingHandlers.clickElement = {
    init: function (element, valueAccessor) {
        var value = ko.unwrap(valueAccessor());
        $(element).on("click", function () {
            $(value)[0].click();
        });
    }
}

// Expand collapse for elements
// Binding parameter = jquery selector for element(s) to expand/collapse or object { target: selector, group: groupname }
// Saves state for if elements are unloaded/loaded
// Adds css classes like bootstrap's collapse for actual expand/collapse (collapse, collapse in)
// TODO: Should be part of tekis components also since it's used there
ko.bindingHandlers.expandCollapse = {
    expandstore: ko.observable({})
}
ko.bindingHandlers.expandCollapse.init = function (element, valueAccessor) {
    var value = ko.unwrap(valueAccessor());
    if (!value) return;
    var group = undefined,
        onchange = undefined,
        animate = false;
    if (typeof value === "object") {
        group = value.group;
        animate = value.animate || false;
        onchange = value.onchange,
        value = value.target;
    }
    if (!value) return;

    var $element = $(element),
        $target = $(value),
        store = ko.bindingHandlers.expandCollapse.expandstore();

    if (typeof store[value] === "undefined") {
        store[value] = {};
        if ($target.length) {
            store[value].expanded = $target.hasClass("in");
        }
        store[value].group = group;
        ko.bindingHandlers.expandCollapse.expandstore(store);
    }
    store[value].element = $element;
    if ($target.length) {
        store[value].target = $target;
        $target.toggleClass("in", store[value].expanded);
    }
    $element.toggleClass("collapsed", !store[value].expanded);
    $element.addClass("clickable");

    $element.on("click", function () {
        var $t = $(value);
        if ($t.length) {
            var expanded = !$t.hasClass("expanded");
            if (expanded) {
                for (var i in store) {
                    var item = store[i];
                    if (item.group && item.group === group && item.target[0] !== $t[0]) {
                        item.expanded = false;
                        item.target.slideUp(animate ? 300 : 0);
                        item.target.toggleClass("expanded", false);
                        item.element.toggleClass("collapsed", true);
                    }
                }
            }
            store[value].expanded = expanded;
            ko.bindingHandlers.expandCollapse.expandstore(store);
            expanded ? $t.slideDown(animate ? 300 : 0) : $t.slideUp(animate ? 300 : 0);
            $t.toggleClass("expanded", expanded);
            $element.toggleClass("collapsed", !expanded);
            if (typeof onchange === "function") {
                var context = ko.contextFor(element);
                onchange(expanded, context && context.$data, context);
            }
        }
    });
}

// Add this binding to elements that are the target of expandCollapse if value for jquery
// selector of expandCollapse is not set when element with expandCollapse binding is 
// rendered (for example if it is part of a css or attr binding)
// Binding parameter = jquery selector for element(s) to expand/collapse
ko.bindingHandlers.expandCollapseTarget = {
    init: function (element, valueAccessor) {
        var value = ko.unwrap(valueAccessor());
        if (!value) return;

        var store = ko.bindingHandlers.expandCollapse.expandstore();
        store[value].target = $(value);
        if (typeof store[value].expanded === "undefined")
            store[value].expanded = $(value).hasClass("expanded");
        ko.bindingHandlers.expandCollapse.expandstore(store);

        $(value).toggle(store[value].expanded);
        $(value).toggleClass("expanded", store[value].expanded);
        store[value].element.toggleClass("collapsed", !store[value].expanded);
    }
}

ko.bindingHandlers.trackInputSelectionPositions = {
    init: function (element, valueAccessor, allValueAccessors) {
        var value = ko.unwrap(valueAccessor());
        var allValues = ko.unwrap(allValueAccessors());
        if (!value || !value.start || !value.end || (!allValues["value"] && !allValues["textInput"])) return;

        var handler = function () {
            value.start(element.selectionStart);
            value.end(element.selectionEnd);
        }
        element.addEventListener("keyup", handler, false);
        element.addEventListener("mouseup", handler, false);
        var inputValue = allValues["value"] || allValues["textInput"];
        inputValue.subscribe(handler);
    }
}

// Using css2 together with css allows dynamic and static css classes on an element at the same time
ko.bindingHandlers['css2'] = ko.bindingHandlers.css;

// Validation extenders

// Based on: https://github.com/Knockout-Contrib/Knockout-Validation/wiki/User-Contributed-Rules#ensure-a-property-of-all-items-is-unique
ko.validation.rules["arrayItemsPropertyValueUnique"] = {
    validator: function (array, params) {
        if (!Array.isArray(array)) throw "Observable with this rule must be an observableArray";
    
        var values = {},
            properties = typeof params === "object" ? params.property : params,
            skipFalsyValues = typeof params === "object" ? params.skipFalsyValues : false;
        properties = Array.isArray(properties) ? properties : [properties];
        for (var index = 0; index < array.length; index++) {
            for (var p in properties) {
                if (properties.hasOwnProperty(p)) {
                    var property = properties[p];
                    var prop = array[index][property];
                    var value = prop();
                    if (skipFalsyValues && !value) continue;
                    if (typeof values[property] === "undefined") values[property] = [];
                    if (values[property].indexOf(value) !== -1) {
                        return false;
                    } else {
                        values[property].push(value);
                    }
                }
            }
        }
        return true;
    },
    message: "The items in the array do not have a unique value for property '{0}'."
};

ko.validation.registerExtenders();

// Observable extends

ko.extenders.title = ko.extenders.title || function (target, title) {
    target.title = title;
}

ko.extenders.readonly = ko.extenders.readonly || function (target, readonly) {
    target.readonly = readonly;
}

ko.extenders.type = ko.extenders.type || function (target, type) {
    target.type = type;
}

ko.extenders.defaultValue = ko.extenders.defaultValue || function (target, value) {
    target.defaultValue = value;
    target(value);
}

ko.extenders.bool = ko.extenders.bool || function (target, bool) {
    target.bool = bool;
}

ko.extenders.flags = ko.extenders.flags || function (target, flags) {
    target.flags = flags;
}