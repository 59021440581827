var vm = vm || {};

vm.VisitHandlingar = function () {
    vm.VisitBase.call(this);
}

vm.VisitHandlingar.prototype = Object.create(vm.VisitBase.prototype);
vm.VisitHandlingar.prototype.constructor = vm.VisitHandlingar;

vm.VisitHandlingar.prototype.fileAdded = function (element, data, event) {
    if (!element.value) return;
    var file = event.target.files[0];
    return dataservice.visit.addDocument(file)
        .then(function () {
            element.value = "";
        })
        .catch(function (ex) {
            element.value = "";
            utils.log(ex);
            vm.global.addNotification("Det gick inte att lägga till filen, försök igen.", "error");
        });
}

vm.VisitHandlingar.prototype.removeFile = function (file) {
    this.visit().documents.remove(file);
}

vm.VisitHandlingar.prototype.openDocument = function (index) {
    var locations = [],
        start = 0;
    this.visit().documents().forEach(function (document, i) {
        if (document.isImage()) {
            locations.push(document.data);
            if (index > i)
                start++;
        }
    });
    vm.imageViewer.show(locations, start);
}

vm.visitHandlingar = new vm.VisitHandlingar();
