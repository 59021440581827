var model = model || {};

model.Visit = function () {
  this.visitType = ko.observable().extend({ title: "Besökstyp" });
  this.isPlanned = ko.observable().extend({ title: "Planerad" });
  this.date = ko.observable().extend({ title: "Datum" });
  this.dnr = ko.observable().extend({ title: "DNR" });
  this.caseId = ko.observable();
  this.contacts = ko.observableArray().extend({ type: model.Party });
  this.controlAreas = ko
    .observableArray()
    .extend({ type: model.ControlAreaWithResult });
  this.notes = ko.observableArray().extend({ type: model.NoteWithResult });
  this.documents = ko.observableArray().extend({ type: model.VisitDocument });
  this.estateName = ko.observable();
  this.caseWorker = ko.observable().extend({ type: model.CaseWorker });
  this.timeBillable = ko
    .observable()
    .extend({ title: "Debiterbar", defaultValue: "0:00" });
  this.timeNotBillable = ko
    .observable()
    .extend({ title: "Ej debiterbar", defaultValue: "0:00" });
  this.tempNote = ko.observable("");

  this.controlAreasWithResults = ko.pureComputed(function () {
    var result = this.controlAreas().filter(function (controlArea) {
      return controlArea.questions().find(function (item) {
        return item.result() !== undefined;
      });
    });
    return result;
  }, this);
  this.notesWithComments = ko.pureComputed(function () {
    var result = this.notes().filter(function (note) {
      return note.comment();
    });
    return result;
  }, this);
  this.totalResults = ko.pureComputed(function () {
    var result = 0;
    this.controlAreas().forEach(function (area) {
      result += area.totalOkResults() + area.totalNotOkResults();
    });
    return result;
  }, this);

  this.selectedForUpload = ko.observable();
  this.uploadStatus = ko.observable();
  this.showTempNote = ko.observable(false);
  this.tempNote = ko.observable("");
};
model.Visit.prototype.toJSON = function () {
  var copy = ko.toJS(this);
  delete copy.totalResults;
  delete copy.selectedForUpload;
  delete copy.uploadStatus;
  return copy;
};

model.ControlAreaWithResult = function () {
  this.category = ko.observable().extend({ title: "Kontrollområde" });
  this.questions = ko
    .observableArray()
    .extend({ type: model.ControlQuestionWithResult });

  this.totalOkResults = ko.pureComputed(function () {
    var result = this.questions().filter(function (item) {
      return item.result() === true;
    });
    return result.length;
  }, this);
  this.totalNotOkResults = ko.pureComputed(function () {
    var result = this.questions().filter(function (item) {
      return item.result() === false;
    });
    return result.length;
  }, this);
  this.categoryWithResultCount = ko.pureComputed(function () {
    var result = this.questions().filter(function (item) {
      return item.result() !== undefined;
    });
    return (
      this.category() +
      " (" +
      result.length +
      " av " +
      this.questions().length +
      ")"
    );
  }, this);
};
model.ControlAreaWithResult.prototype.toJSON = function () {
  var copy = ko.toJS(this);
  delete copy.totalOkResults;
  delete copy.totalNotOkResults;
  delete copy.categoryWithResultCount;
  return copy;
};

model.ControlQuestionWithResult = function () {
  this.question = ko.observable().extend({ title: "Kontrollpunkt" });
  this.phrases = ko.observableArray().extend({ type: String });
  this.result = ko.observable().extend({ title: "Resultat" });
  this.comment = ko.observable().extend({ title: "Notering" });

  this.isNoteExpanded = ko.observable(false);
  this.unusedPhrases = ko.pureComputed(function () {
    var comment = this.comment() || "";
    return this.phrases().filter(function (phrase) {
      return comment.indexOf(phrase) === -1;
    });
  }, this);
};
model.ControlQuestionWithResult.prototype.toJSON = function () {
  var copy = ko.toJS(this);
  delete copy.phrases;
  delete copy.isNoteExpanded;
  delete copy.unusedPhrases;
  return copy;
};

model.NoteWithResult = function () {
  this.category = ko.observable().extend({ title: "Kategori" });
  this.phrases = ko.observableArray().extend({ type: String });
  this.comment = ko.observable().extend({ title: "Notering" });

  this.unusedPhrases = ko.pureComputed(function () {
    var comment = this.comment() || "";
    return this.phrases().filter(function (phrase) {
      return comment.indexOf(phrase) === -1;
    });
  }, this);
};
model.NoteWithResult.prototype.toJSON = function () {
  var copy = ko.toJS(this);
  delete copy.phrases;
  delete copy.unusedPhrases;
  return copy;
};

model.VisitDocument = function () {
  this.filename = ko.observable();
  this.comment = ko.observable();
  this.data = ko.observable();

  this.isImage = ko.pureComputed(function () {
    var filename = this.filename();
    return utils.hasFileImageExtension(filename);
  }, this);
};
model.VisitDocument.prototype.toJSON = function () {
  var copy = ko.toJS(this);
  delete copy.isImage;
  return copy;
};

model.VisitReportEmail = function () {
  this.send = ko.observable(false).extend({ title: "Skicka" });
  this.sendAllDocuments = ko
    .observable()
    .extend({ title: "Skicka handlingar", defaultValue: true });
  this.recipients = ko
    .observableArray()
    .extend({ type: String, title: "Mottagare" });
  this.independentRecipients = ko
    .observable()
    .extend({ title: "Ange e-postadress(er)" });
  this.subject = ko.observable().extend({ title: "Ämne", required: true });
  this.body = ko.observable().extend({ title: "Meddelande" });
};
