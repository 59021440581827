var vm = vm || {};

vm.confirmDialog = {
    visible: ko.observable(),
    title: ko.observable(),
    text: ko.observable(),
    buttonYesText: ko.observable(),
    buttonNoText: ko.observable()
}

vm.confirmDialog.hide = function () {
    vm.confirmDialog.visible(false);
}

vm.confirmDialog.yes = function () {
    vm.confirmDialog.resolve();
}

vm.confirmDialog.no = function () {
    vm.confirmDialog.reject();
}

vm.confirmDialog.show = function (options, title) {
    if (!options || typeof options !== "object") {
        options = { text: options };
    }
    vm.confirmDialog.text(options.text || "Är du säker?");
    vm.confirmDialog.title(options.title || title || "Är du säker?");
    vm.confirmDialog.buttonYesText(options.buttonYesText || "Ja");
    vm.confirmDialog.buttonNoText(options.buttonNoText || "Nej");
    vm.confirmDialog.visible(true);

    return new Promise(function (resolve, reject) {
        vm.confirmDialog.resolve = resolve;
        vm.confirmDialog.reject = reject;
    });
}
