var model = model || {};
model.settings = {};

model.Settings = function () {
    this.baseData = ko.observable().extend({ type: model.settings.StaticSettings });
    this.settings = ko.observable().extend({ type: model.settings.DynamicSettings });
}

model.settings.StaticSettings = function () {
    this.watchTypes = ko.observableArray().extend({ title: "Bevakningstyper", type: model.settings.Type });
    this.units = ko.observableArray().extend({ title: "Enheter", type: model.settings.Type });
    this.boards = ko.observableArray().extend({ title: "Nämnder", type: model.settings.BoardType });
    this.eventTypes = ko.observableArray().extend({ title: "Händelsetyper", type: model.settings.EventType });
    this.documentTypes = ko.observableArray().extend({ title: "Handlingstyper", type: model.settings.Type });
    this.roles = ko.observableArray().extend({ title: "Roller", type: model.settings.Type });
    this.reportTypes = ko.observableArray().extend({ title: "Rapportmallar", type: model.settings.Type });
}

model.settings.DynamicSettings = function () {
    var self = this;
    this.minCaseRegDate = ko.observable().extend({ title: "Tidigaste registreringsdatum för ärenden" });
    this.showInactive = ko.observable().extend({ title: "Visa inaktiva handläggare" });
    this.controlRole = ko.observable().extend({ title: "Roll kontrollansvarig", required: true });
    this.applicantRole = ko.observable().extend({ title: "Roll sökande", required: true });
    this.estateOwnerRole = ko.observable().extend({ title: "Roll fastighetsägare", required: true });
    this.units = ko.observableArray().extend({ title: "Enhet", type: String });
    this.boards = ko.observableArray().extend({ title: "Nämnd", type: String });
    this.emailEventType = ko.observable().extend({ title: "Händelsetyp" });
    this.emailEventKind = ko.observable().extend({ title: "Händelseslag" });
    this.emailEventHeading = ko.observable().extend({ title: "Händelserubrik", required: { onlyIf: function () { return self.emailEventType(); } } });
    this.reportTemplates = ko.observableArray().extend({ type: model.settings.ReportTemplate, arrayItemsPropertyValueUnique: { params: { property: ["name", "filename"] }, message: "Rapportmaller måste ha unika namn och filnamn" } });
    this.visitTypes = ko.observableArray().extend({ title: "Besökstyper", type: model.settings.VisitType, arrayItemsPropertyValueUnique: { params: { property: "watchType", skipFalsyValues: true }, message: "Bevakningstypen används redan" } });
}

model.settings.Type = function () {
    this.id = ko.observable().extend({ title: "ID", integer: true, required: true, defaultValue: 0 });
    this.name = ko.observable().extend({ title: "Namn" });
    this.description = ko.observable().extend({ title: "Beskrivning" });

    this.longName = ko.pureComputed(function () {
        var name = this.name(),
            description = this.description();
        return name && description ? name + " - " + description : name || description;
    }, this).extend({ title: "Typ" });
}
model.settings.Type.prototype.toJSON = function () {
    var copy = ko.toJS(this);
    delete copy.longName;
    return copy;
}

model.settings.BoardType = function () {
    model.settings.Type.call(this);
    this.units = ko.observableArray().extend({ type: Number });
}
model.settings.BoardType.prototype = Object.create(model.settings.Type.prototype);
model.settings.BoardType.prototype.constructor = model.settings.BoardType;

model.settings.EventType = function () {
    model.settings.Type.call(this);
    this.eventKinds = ko.observableArray().extend({ title: "", type: model.settings.Type });
}
model.settings.EventType.prototype = Object.create(model.settings.Type.prototype);
model.settings.EventType.prototype.constructor = model.settings.EventType;

model.settings.ReportTemplate = function () {
    this.name = ko.observable().extend({ title: "Namn", required: true });
    this.filename = ko.observable().extend({ title: "Filnamn" });
    this.filedata = ko.observable();
}

model.settings.VisitType = function () {
    var self = this;
    this.id = ko.observable();
    this.isActive = ko.observable().extend({ title: "Aktiv", bool: true, defaultValue: true });
    this.description = ko.observable().extend({ title: "Beskrivning", required: true });
    this.canBeUnplanned = ko.observable().extend({ title: "Kan vara oplanerat", bool: true, defaultValue: true });
    this.watchType = ko.observable().extend({ title: "Bevakningstyp", required: { onlyIf: function () { return !self.canBeUnplanned(); } } });
    this.eventType = ko.observable().extend({ title: "Händelsetyp", required: true });
    this.eventKind = ko.observable().extend({ title: "Händelseslag" });
    this.heading = ko.observable().extend({ title: "Händelserubrik", required: true });
    this.protocolDocumentType = ko.observable().extend({ title: "Handlingstyp protokoll", required: true });
    this.attachmentsDocumentType = ko.observable().extend({ title: "Handlingstyp övrigt", required: true });
    this.reportType = ko.observable().extend({ title: "Rapportmall", required: true });
    this.controlAreas = ko.observableArray().extend({ title: "Kontrollområden", type: model.settings.ControlArea, defaultValue: [] });
    this.notes = ko.observableArray().extend({ title: "Noteringar", type: model.settings.Note, defaultValue: [] });
    this.enabledViews = ko.observable().extend({ title: "Aktiva vyer", flags: ["Närvarande", "Kontrollpunkter", "Noteringar", "Handlingar"], defaultValue: 15 });
    this.enableEmailReport = ko.observable().extend({ title: "Tillåt skicka rapport/handlingar via epost", defaultValue: true });
    this.showCaseNote = ko.observable().extend({ title: "Visa ärendeanteckning", defaultValue: true });
    this.predefinedEmail = ko.observable().extend({ title: "Fördefinierat e-postmeddelande" });
}

model.settings.ControlArea = function () {
    this.category = ko.observable().extend({ title: "Kontrollområde", required: true });
    this.questions = ko.observableArray().extend({ type: model.settings.ControlQuestion });
}

model.settings.ControlQuestion = function () {
    this.question = ko.observable().extend({ title: "Kontrollpunkt", required: true });
    this.phrases = ko.observableArray().extend({ type: String });
}

model.settings.Note = function () {
    this.category = ko.observable().extend({ title: "Notering", required: true });
    this.phrases = ko.observableArray().extend({ type: String });
}