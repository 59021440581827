var vm = vm || {};

vm.VisitSammanstallning = function () {
  vm.VisitBase.call(this);

  this.visitContacts = dataservice.visit.data().visitContacts;
  this.caseWorkers = dataservice.visit.caseWorkers;
  this.showCloseVisitDialog = ko.observable();
  this.visitReportEmail = dataservice.visit.visitReportEmail;
  this.possibleProtocolRecipients =
    dataservice.visit.possibleProtocolRecipients;
  this.visitType = dataservice.visit.visitType;
  this.sendReportEnabled = config.emailReportEnabled;

  this.hasResults = ko.pureComputed(function () {
    var hasContacts = this.visitContacts().length,
      hasControlAreaResults = this.visit().controlAreasWithResults().length,
      hasNotesWithComments = this.visit().notesWithComments().length,
      hasDocuments = this.visit().documents().length;
    return (
      hasContacts ||
      hasControlAreaResults ||
      hasNotesWithComments ||
      hasDocuments
    );
  }, this);

  this.timeBillableHours = ko.pureComputed({
    read: function () {
      return this.visit().timeBillable().split(":")[0];
    },
    write: function (value) {
      var minutes = this.visit().timeBillable().split(":")[1];
      this.visit().timeBillable(Math.max(value, 0) + ":" + minutes);
    },
    owner: this,
  });
  this.timeBillableMinutes = ko.pureComputed({
    read: function () {
      return this.visit().timeBillable().split(":")[1].padStart(2, "0");
    },
    write: function (value) {
      var hours = this.visit().timeBillable().split(":")[0];
      this.visit().timeBillable(
        hours +
          ":" +
          Math.min(Math.max(value, 0), 59).toString().padStart(2, "0")
      );
    },
    owner: this,
  });
  this.timeNotBillableHours = ko.pureComputed({
    read: function () {
      return this.visit().timeNotBillable().split(":")[0];
    },
    write: function (value) {
      var minutes = this.visit().timeNotBillable().split(":")[1];
      this.visit().timeNotBillable(
        Math.max(value, 0).toString() + ":" + minutes
      );
    },
    owner: this,
  });
  this.timeNotBillableMinutes = ko.pureComputed({
    read: function () {
      return this.visit().timeNotBillable().split(":")[1].padStart(2, "0");
    },
    write: function (value) {
      var hours = this.visit().timeNotBillable().split(":")[0];
      this.visit().timeNotBillable(
        hours +
          ":" +
          Math.min(Math.max(value, 0), 59).toString().padStart(2, "0")
      );
    },
    owner: this,
  });
};

vm.VisitSammanstallning.prototype = Object.create(vm.VisitBase.prototype);
vm.VisitSammanstallning.prototype.constructor = vm.VisitSammanstallning;

vm.VisitSammanstallning.prototype.openDocument = function (index) {
  var locations = [],
    start = 0;
  this.visit()
    .documents()
    .forEach(function (document, i) {
      if (document.isImage()) {
        locations.push(document.data);
        if (index > i) start++;
      }
    });
  vm.imageViewer.show(locations, start);
};

vm.VisitSammanstallning.prototype.previewReport = function () {
  vm.global.isBusy(true);
  return dataservice.visit
    .previewVisit()
    .then(function (result) {
      var report = "data:application/pdf;base64," + result.report;
      vm.pdfViewer.show(report);
      vm.global.isBusy(false);
    })
    .catch(function (e) {
      vm.global.isBusy(false);
      vm.global.addNotification(
        "Det gick inte att skapa protokollet, vänligen försök igen.",
        "error"
      );
      throw e;
    });
};

vm.VisitSammanstallning.prototype.toggleCloseVisitDialog = function () {
  this.showCloseVisitDialog(!this.showCloseVisitDialog());
  this.validationErrors.showAllMessages();
};

vm.VisitSammanstallning.prototype.commit = function () {
  var self = this;
  vm.global.isBusy(true);
  return dataservice.visit
    .uploadVisit()
    .then(function () {
      return vm.VisitBase.prototype.commit.call(self).then(function () {
        vm.global.addNotification("Besöket har sparats.", "success");
        setTimeout(function () {
          navigation.navigateToView(config.closeVisitTargetView);
        }, 200);
        vm.global.isBusy(false);
      });
    })
    .catch(function (e) {
      vm.global.isBusy(false);
      vm.global.addNotification(
        "Det gick inte att spara besöket, vänligen försök igen.",
        "error"
      );
      throw e;
    });
};

vm.VisitSammanstallning.prototype.insertPredefinedEmail = function () {
  this.visitReportEmail().body(
    (this.visitReportEmail().body()
      ? this.visitReportEmail().body() + "\n"
      : "") + this.visitType().predefinedEmail()
  );
};

vm.visitSammanstallning = new vm.VisitSammanstallning();
