var vm = vm || {};

vm.Global = function () {
    // Views
    this.activeView = ko.pureComputed(function () { return navigation.activeView() || {}; });
    this.activeViewParams = navigation.activeViewParams;
    this.views = navigation.views;
    this.enabledViews = navigation.enabledViews;
    // Notifications
    this.timerId = 0;
    this.notification = ko.observable();
    // Busy indicator
    this.isBusy = ko.observable(false).extend({ rateLimit: 200 });
    // Offline/online
    this.forceOffline = server.forceOffline;
    this.isOnline = server.isOnline;
    this.serviceWorkerRegistration = ko.observable(false);
    this.showNewVersionDialog = ko.pureComputed(function () {
        return !!this.serviceWorkerRegistration();
    }, this);
    var self = this;
    this.loadNewVersion = function () {
        self.serviceWorkerRegistration().waiting.postMessage("skipWaiting");
    }

    this.activeViewComponent = ko.pureComputed(function () {
        var activeView = this.activeView();
        return activeView ? "view" + activeView.id : null;
    }, this);

    this.activeViewIsDirty = ko.pureComputed(function () {
        var activeView = this.activeView();
        if (activeView && activeView.vm && activeView.vm.isDirty)
            return activeView.vm.isDirty();
        return false;
    }, this);

    this.canCurrentViewRefresh = ko.pureComputed(function () {
        var activeView = this.activeView();
        return this.isOnline() && activeView && activeView.vm && activeView.vm.refresh;
    }, this);
}

vm.Global.prototype.dismissNotification = function () {
    this.notification(null);
    clearTimeout(this.timerId);
    this.timerId = 0;
}

vm.Global.prototype.addNotification = function (message, type) {
    type = type || "info";
    utils.logDebug("Notification: " + type + ": " + message);
    this.notification({ message: message, type: type });
    if (this.timerId)
        clearTimeout(this.timerId);
    this.timerId = setTimeout(this.dismissNotification.bind(this), config.notificationTimeout);
}

vm.Global.prototype.workOffline = function () {
    this.forceOffline(true);
}

vm.Global.prototype.workOnline = function () {
    this.forceOffline(false);
}

vm.Global.prototype.refreshCurrentView = function () {
    var activeView = this.activeView();
    if (activeView && activeView.vm && activeView.vm.refresh && (!activeView.vm.isDirty || !activeView.vm.isDirty()))
        activeView.vm.refresh();
};

vm.Global.prototype.init = function () {
    this.isOnline.subscribe(function (online) {
        if (!online) return;
        // Refresh the current view when app goes online, this will cause most views to load their data if they don't have any cached
        var activeView = this.activeView();
        if (activeView && activeView.vm && activeView.vm.init)
            activeView.vm.init(this.activeViewParams());
    }, this);
}

vm.global = new vm.Global;
