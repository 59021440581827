var model = model || {};

model.Object = function () {
  this.id = ko
    .observable()
    .extend({ title: "ID", integer: true, required: true, defaultValue: 0 });
  this.estateName = ko.observable().extend({ title: "Fastighetsbeteckning" });
  this.address = ko.observable().extend({ title: "Adress" });
};

model.Case = function () {
  this.id = ko
    .observable()
    .extend({ title: "ID", integer: true, required: true, defaultValue: 0 });
  this.objectId = ko.observable().extend({ title: "Objekt ID", integer: true });
  this.dnr = ko.observable().extend({ title: "DNR" });
  this.date = ko.observable().extend({ title: "Ärendets ankomstdatum" });
  this.description = ko.observable().extend({ title: "Ärendemening" });
  this.note = ko.observable().extend({ title: "Anteckning" });

  this.events = ko.observableArray().extend({ type: model.Event });
  this.parties = ko.observableArray().extend({ type: model.Party });
};

model.Event = function () {
  this.id = ko
    .observable()
    .extend({ title: "ID", integer: true, required: true, defaultValue: 0 });
  this.caseId = ko.observable().extend({ title: "Event ID", integer: true });
  this.caption = ko.observable().extend({ title: "Rubrik" });
  this.date = ko.observable().extend({ title: "Datum" });
  this.note = ko.observable().extend({ title: "Anteckning" });

  this.documents = ko.observableArray().extend({ type: model.Document });
  this.documentsWithDocuments = ko.pureComputed(function () {
    return this.documents().filter(function (document) {
      return document.hasDocument();
    }, this);
  }, this);
  this.documentsWithCachedDocuments = ko.pureComputed(function () {
    return this.documents().filter(function (document) {
      return document.hasDocument() && document.file();
    }, this);
  }, this);
};
model.Event.prototype.toJSON = function () {
  var copy = ko.toJS(this);
  delete copy.documentsWithDocuments;
  delete copy.documentsWithCachedDocuments;
  return copy;
};

model.Document = function () {
  this.id = ko
    .observable()
    .extend({ title: "ID", integer: true, required: true, defaultValue: 0 });
  this.documentId = ko
    .observable()
    .extend({ title: "Dokument ID", integer: true });
  this.caption = ko.observable().extend({ title: "Rubrik" });
  this.date = ko.observable().extend({ title: "Datum" });
  this.hasDocument = ko.observable().extend({ title: "Handling finns" });
  this.documentTypeDescription = ko
    .observable()
    .extend({ title: "Handlingstyp Beskrivning" });

  this.file = ko.observable();
  this.contentBase64 = ko.pureComputed(function () {
    var file = this.file();
    return file ? file.content : "";
  }, this);
  this.contentDataUrl = ko.pureComputed(function () {
    var file = this.file();
    return file
      ? "data:" +
          file.contentType +
          ";base64," +
          encodeURIComponent(file.content)
      : "";
  }, this);
  this.contentBase64ContentType = ko.pureComputed(function () {
    var file = this.file();
    return file ? file.contentType : "";
  }, this);
  this.contentBase64Filename = ko.pureComputed(function () {
    var file = this.file();
    return file ? file.name : "";
  }, this);
  this.isPdf = ko.pureComputed(function () {
    var contentType = this.contentBase64ContentType();
    return contentType === "application/pdf";
  }, this);
};
model.Document.prototype.toJSON = function () {
  var copy = ko.toJS(this);
  delete copy.file;
  delete copy.contentBase64;
  delete copy.contentBase64Filename;
  return copy;
};

model.Party = function () {
  this.id = ko
    .observable()
    .extend({ title: "ID", integer: true, required: true, defaultValue: 0 });
  this.name = ko.observable().extend({ title: "Namn", required: true });
  this.role = ko.observable().extend({ title: "Roll" });
  this.telephone = ko.observable().extend({ title: "Telefon" });
  this.email = ko.observable().extend({ title: "E-post" });

  this.nameEmail = ko.pureComputed(function () {
    var name = this.name(),
      email = this.email(),
      role = this.role();
    return (
      (name && email ? name + " <" + email + ">" : email || name) +
      (role ? " (" + role + ")" : "")
    );
  }, this);
};
model.Party.prototype.toJSON = function () {
  var copy = ko.toJS(this);
  delete copy.nameEmail;
  return copy;
};
