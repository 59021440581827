var utils = utils || {};

utils.debug = window.location.search.indexOf("debug") !== -1 || config.debug;
utils.remotelogging =
  window.location.search.indexOf("remotelogging") !== -1 ||
  config.remoteLogging;
utils.alertlogging = window.location.search.indexOf("alertlogging") !== -1;
utils.htmllogging = window.location.search.indexOf("htmllogging") !== -1;

if (utils.htmllogging) {
  utils.loglines = ko.observableArray();
  utils.loglines.subscribe(function () {
    var el = document.getElementById("htmllog");
    setTimeout(function () {
      el.scrollTop = el.scrollHeight;
    }, 500);
  });
  document.body.innerHTML +=
    '<div id="htmllog" style="position: fixed; bottom: 0; height: 200px; font: 11px Monospace; color: #ccc; word-wrap: break-word; overflow: auto" data-bind="foreach: utils.loglines"><div data-bind="text: $data"></div></div>';
}

utils.log = function () {
  var separator = " ";
  var now = new Date().toLocaleString("sv");
  var message = "";
  for (var i = 0; i < arguments.length; i++) {
    var arg = arguments[i];
    // Is param exception, log stack/message
    arg = arg instanceof Error ? arg.stack || arg.message : arg;
    var log;
    try {
      log = typeof arg === "object" ? JSON.stringify(arg) : arg;
    } catch (e) {
      log = arg;
    }
    message += log + (i < arguments.length - 1 ? separator : "");
  }

  // Filter password from login
  message = message.replace(/(\\"password\\":)\\".*?\\"/, '$1\\"********\\"');

  utils.originalConsoleLog.apply(null, arguments);

  if (utils.alertlogging) alert(now + separator + message.substring(0, 1000));

  if (utils.remotelogging) {
    offlineCache.query("api/system/log", {
      method: "POST",
      dataType: "json",
      contentType: "application/json",
      data: JSON.stringify({
        date: now,
        message: message,
      }),
    });
  }

  if (utils.htmllogging) utils.loglines.push(now + separator + message);
};

// Override console.log and console.dir so if used anywhere we can format the message
utils.originalConsoleLog = console.log.bind(console);
utils.originalConsoleDir = console.dir.bind(console);
console.log = utils.log;
console.dir = utils.log;

utils.logConsole = utils.originalConsoleLog;

utils.logDebug = function () {
  utils.debug && utils.log.apply(this, arguments);
};

// Log all uncatched errors
window.onerror = function () {
  utils.log.apply(this, arguments);
};

utils.getApiUrl = function (url) {
  var result = "";
  // Test if url has configured api prefix (protocol independent)
  var prefixTest = new RegExp(
    "^(https?:)?" + config.apiUrl.replace(/^https?:/, "") + ".*$"
  );
  if (!prefixTest.test(url)) {
    // Url does not contain configured api prefix, add it
    result = config.apiUrl;
    if (result[result.length - 1] !== "/")
      // Add slash if not present
      result += "/";
  }
  result += url;
  // Same protocol as whole site
  result = result.replace(/^https?:/, document.location.protocol);
  return result;
};

utils.query = function (url, options, isAbsoluteUrl) {
  if (!isAbsoluteUrl) {
    url = utils.getApiUrl(url);
  }

  options = options || {};
  options.dataType = options.dataType || "json";
  options.method = options.method || "GET";
  options.cache = options.cache || false;
  if (options.method !== "GET") {
    options.contentType =
      typeof options.contentType === "boolean"
        ? options.contentType
        : options.contentType || "application/json; charset=utf-8";
  }

  utils.logDebug(
    "AJAX Request" + (server.isOnline() ? "" : " (offline)") + ":",
    "url:",
    url,
    "options:",
    options
  );

  var request = offlineCache.query(url, options);

  if (utils.debug) {
    request.then(function (response) {
      utils.log(
        "AJAX Result" + (server.isOnline() ? "" : " (offline)") + ":",
        "url:",
        url,
        "response:",
        response
      );
    });
  }

  request.catch(function (response) {
    utils.log(
      "AJAX Error" + (server.isOnline() ? "" : " (offline)") + ":",
      "url:",
      url,
      "response:",
      response
    );
  });

  return request;
};

utils.ajaxCallbacks = [];
utils.ajax = function (url, options) {
  var ajax;
  var result = new Promise(function (resolve, reject) {
    options = options || {};
    options.mode = "cors";
    options.cache = "no-cache";
    options.credentials = "include";
    //options.redirect = "error";
    options.headers = options.headers || {};
    options.headers["Accept"] = "*/*";
    if (options.method && options.method !== "GET") {
      options.headers["Content-Type"] =
        options.contentType || "application/json";
      options.body = options.data;
    } else if (options.data) {
      var qs = Object.keys(options.data).reduce(function (acc, cur) {
        var val =
          encodeURIComponent(cur) +
          "=" +
          encodeURIComponent(
            options.data[cur] === "undefined" || options.data[cur] === "null"
              ? ""
              : options.data[cur] || ""
          );
        return acc === "" ? val : acc + "&" + val;
      }, "");
      if (qs) {
        url = url + (url.indexOf("?") !== -1 ? "&" : "?") + qs;
      }
    }

    ajax = fetch(url, options);
    ajax.then(
      function (response) {
        if (!response.ok || response.status < 200 || response.status >= 300) {
          reject({ response: response, status: response.status });
          return;
        }
        if (response.status === 204) {
          resolve({ status: 204 });
          return;
        }
        return (
          url.indexOf(".html") !== -1 ? response.text() : response.json()
        ).then(function (data) {
          resolve({ data: data, status: response.status });
        });
      },
      function (error) {
        reject({ status: 500, error: error });
        return;
      }
    );
  });
  utils.ajaxCallbacks.forEach(function (cb) {
    if (!cb.url || url.match(cb.url)) {
      result = result.then(
        function (x) {
          var result = cb.success && cb.success(x);
          if (result) return result;
          return x;
        },
        function (e) {
          var result = cb.error && cb.error(e);
          if (result) return result;
          throw e;
        }
      );
    }
  });
  result.abort = function () {
    //ajax.abort();
  };
  return result;
};

utils.firstOrDefault = function (elements, filter, defaultValue) {
  var result = null;
  for (var i in elements) {
    if (elements.hasOwnProperty(i)) {
      var element = elements[i];
      if (filter(element, i) === true) {
        result = element;
        break;
      }
    }
  }
  return result || (typeof defaultValue !== "undefined" ? defaultValue : null);
};

utils.isObservableArray = function (instance) {
  // Taken from ko.validation
  return (
    !!instance &&
    typeof instance["remove"] === "function" &&
    typeof instance["removeAll"] === "function" &&
    typeof instance["destroy"] === "function" &&
    typeof instance["destroyAll"] === "function" &&
    typeof instance["indexOf"] === "function" &&
    typeof instance["replace"] === "function"
  );
};

// Today as string in YYYY-MM-DD format, updated every minute
utils.today = ko.observable();
utils.setToday = function () {
  var now = new Date();
  var month = now.getMonth() + 1;
  var day = now.getDate();
  var today =
    now.getFullYear() +
    "-" +
    (month <= 9 ? "0" + month : month) +
    "-" +
    (day <= 9 ? "0" + day : day);
  utils.today(today);
};
utils.setToday();
setInterval(function () {
  utils.setToday();
}, 60000);

utils.extend = $.extend;

utils.replaceRouteParams = function (route, params) {
  var r = route;

  for (var param in params) {
    if (params.hasOwnProperty(param)) {
      var value = ko.unwrap(params[param]);
      var paramRegExp = new RegExp(":" + param + "[?]?");
      r = r.replace(paramRegExp, value);
    }
  }
  // Remove empty params
  r = r.replace(/:\w+[\?]?/, "");

  return r;
};

utils.hasFileImageExtension = function (file) {
  if (!file) return false;
  var extension = file.match(/\.?(\w+$)/);
  if (!extension || extension.length < 2) return false;
  return "png jpg jpeg gif bmp dib".indexOf(extension[1].toLowerCase()) >= 0;
};

utils.base64ImageToImgSrc = function (data, filename) {
  if (!data) return null;
  var prefix = "";
  if (data.slice(0, 10) !== "data:image") {
    var extension = filename.match(/\.?(\w+$)/);
    if (!extension || extension.length < 2) return null;
    extension = extension[1].toLowerCase();

    prefix =
      "data:image/" + (extension === "jpg" ? "jpeg" : extension) + ";base64,";
  }
  return prefix + data;
};

utils.copyObjectIntoObservable = function (object, data) {
  var unwrapped = ko.unwrap(data);
  var isArray = Array.isArray(object);

  // TODO: These 3 bits can be combined into one and with much simpler code

  if (isArray && object.length === 0 && utils.isObservableArray(data)) {
    data([]);
    return;
  }

  // If object is null clear data recursively if data is an object/array as it might have had values before and so we can bind to nested properties without errors
  if (!object) {
    for (var unwrappedprop in unwrapped) {
      if (unwrapped.hasOwnProperty(unwrappedprop)) {
        var property = unwrapped[unwrappedprop];
        if (
          typeof ko.unwrap(property) === "object" ||
          Array.isArray(ko.unwrap(property))
        ) {
          utils.copyObjectIntoObservable(null, property);
        } else if (property.type) {
          if (!property()) {
            property(new property.type());
          }
          utils.copyObjectIntoObservable(null, property);
        } else if (ko.isWritableObservable(property)) {
          property(utils.isObservableArray(property) ? [] : undefined);
        }
      }
    }
  }

  for (var prop in object) {
    if (unwrapped.hasOwnProperty(prop) || isArray) {
      var obs = isArray ? data : unwrapped[prop];
      var func = (function (observable, property) {
        var obj = isArray ? object : object[property];
        if (utils.isObservableArray(observable)) {
          if (!observable.type) {
            throw new Error(
              "Observable Array does not have type set. Use extend({type: TYPE})"
            );
          }
          if (obj) {
            obj.forEach(function (item, index) {
              if (observable()[index]) {
                if (
                  observable.type === String ||
                  observable.type === Number ||
                  observable.type === Boolean
                )
                  observable()[index] = item;
                else utils.copyObjectIntoObservable(item, observable()[index]);
              } else {
                var element = new observable.type();
                if (
                  observable.type === String ||
                  observable.type === Number ||
                  observable.type === Boolean
                )
                  element = item;
                else utils.copyObjectIntoObservable(item, element);
                observable.push(element);
              }
            });
            for (var i = obj.length; i < observable().length; ) {
              observable.pop();
            }
          } else {
            observable([]);
          }
        } else if (observable.type) {
          if (!observable()) {
            observable(new observable.type());
          }
          utils.copyObjectIntoObservable(obj, observable);
        } else if (ko.isWritableObservable(observable)) {
          observable(obj);
        }
      })(obs, prop);
    }
  }
};

utils.removeHoverEffects = function () {
  var touch = "ontouchstart" in document.documentElement;
  if (touch) {
    for (var si in document.styleSheets) {
      if (document.styleSheets.hasOwnProperty(si)) {
        var styleSheet = document.styleSheets[si];
        if (!styleSheet.rules) continue;

        for (var ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
          if (!styleSheet.rules[ri].selectorText) continue;

          if (styleSheet.rules[ri].selectorText.match(":hover")) {
            styleSheet.deleteRule(ri);
          }
        }
      }
    }
  }
};

utils.noChosenForMobile = function () {
  if (/Android/i.test(window.navigator.userAgent)) {
    $.fn.chosen = function () {
      return this;
    };
  }
  if (/iPad/i.test(window.navigator.userAgent)) {
    $.fn.chosen = function () {
      return this;
    };
  }
};

utils.isIE =
  /MSIE/i.test(window.navigator.userAgent) ||
  /Trident/i.test(window.navigator.userAgent);
utils.isEdge = /Edge/i.test(window.navigator.userAgent);
utils.isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

utils.geolocation = {};
utils.geolocation.isSupported = typeof navigator.geolocation !== "undefined";
utils.geolocation.get = function () {
  return new Promise(function (resolve, reject) {
    if (utils.geolocation.isSupported) {
      var options = config.geolocationPositionOptions;
      navigator.geolocation.getCurrentPosition(
        function (position) {
          resolve(position.coords);
        },
        function (positionError) {
          reject(positionError);
        },
        options
      );
    } else {
      reject("Geolocation not supported");
    }
  });
};
