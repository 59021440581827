var dataservice = dataservice || {};

dataservice.Search = function () {
  dataservice.Base.call(this);

  // Data that we should be able to rollback and/or check dirty state of
  this.data().selectedCaseWorkers = ko
    .observableArray()
    .extend({ type: model.CaseWorker });
  this.data().searchText = ko.observable();
  this.eventDocumentsOffline = ko
    .observableArray()
    .extend({ type: model.EventDocumentsOffline });
  this.mapOptions = ko.observable();

  // Other data
  this.settings = dataservice.Base.data.settings;
  this.allCaseWorkers = dataservice.Base.data.caseWorkers;
  this.caseWorkers = ko.pureComputed(function () {
    return dataservice.Base.data.caseWorkers().filter(function (caseWorker) {
      var isActive = this.settings().settings().showInactive();
      return caseWorker.isActive() || isActive;
    }, this);
  }, this);
  this.searchResults = ko.observableArray().extend({ type: model.SearchHit });
};

dataservice.Search.prototype = Object.create(dataservice.Base.prototype);
dataservice.Search.prototype.constructor = dataservice.Search;

utils.extend(
  dataservice.Search.prototype,
  (function () {
    var generateVisitsForSearchHits = function () {
        var visitTypes = this.settings().settings().visitTypes(),
          caseWorkers = this.allCaseWorkers();

        this.searchResults().forEach(function (searchHit) {
          searchHit.visits.removeAll();

          searchHit.plannedVisits().forEach(function (plannedVisit) {
            var visitType = visitTypes.find(function (visitType) {
              return visitType.watchType() === plannedVisit.watchType();
            });
            if (visitType && visitType.isActive()) {
              var caseWorker = caseWorkers.find(function (caseWorker) {
                return caseWorker.id() === plannedVisit.caseWorkerId();
              });
              var visit = new searchHit.visits.type();
              visit.visitTypeId(visitType.id());
              visit.isPlanned(true);
              visit.description(
                visitType.description() +
                  ", " +
                  plannedVisit.date() +
                  (caseWorker ? ", " + caseWorker.fullName() : "")
              );
              searchHit.visits.push(visit);
            }
          });
          visitTypes.forEach(function (visitType) {
            if (visitType.canBeUnplanned() && visitType.isActive()) {
              var visit = new searchHit.visits.type();
              visit.visitTypeId(visitType.id());
              visit.isPlanned(false);
              visit.description(visitType.description() + ", Oplanerat");
              searchHit.visits.push(visit);
            }
          });
        });
      },
      searchUsingText = function () {
        var self = this;
        dataapi.search.abort();
        return dataapi.search
          .usingText(
            this.data().searchText(),
            this.data().selectedCaseWorkers()
          )
          .then(function (result) {
            var cached =
              result && result.data
                ? offlineCache.getCachedCases()
                : Promise.resolve([]);
            return cached
              .then(function (cachedCases) {
                cachedCases.forEach(function (item) {
                  if (!item) return;
                  var cachedSearchResults = result.data.find(function (
                    searchResult
                  ) {
                    return item.case.id === searchResult.caseId;
                  });
                  if (cachedSearchResults) {
                    cachedSearchResults.isCached = item.isCached;
                  }
                });
                if (!result.data) return promise;
                var promise = Promise.resolve();
                result.data.forEach(function (r) {
                  promise = promise.then(function () {
                    return offlineCache
                      .getCachedVisit(r.dnr)
                      .then(function (visit) {
                        r.visit = visit;
                        r.hasVisit = !!visit;
                      });
                  });
                });
                return promise;
              })
              .then(function () {
                self.searchResults([]);
                self.copyObjectIntoObservable(result.data, self.searchResults);
                self.generateVisitsForSearchHits();
                self.commit();
                return self.searchResults();
              });
          });
      },
      removeCaseFromCache = function (searchHit) {
        return offlineCache.removeCaseFromCache(searchHit.caseId(), true);
      },
      removeVisitFromCache = function (searchHit) {
        return offlineCache.removeCachedVisit(searchHit.dnr());
      },
      getObjectAndCaseForOffline = function (objectId, caseId) {
        var result = dataapi.visit.getObject(objectId);
        result = result.then(function () {
          return dataapi.visit.getCase(caseId);
        });
        return result;
      },
      makeAvailableOffline = function (searchHit) {
        var result = getObjectAndCaseForOffline(
          searchHit.objectId(),
          searchHit.caseId()
        );
        result = result.then(function () {
          return offlineCache.makeSearchHitAvailableOffline(
            searchHit.caseId(),
            ko.toJS(searchHit.plannedVisits),
            searchHit.controlResponsible(),
            searchHit.estateId(),
            ko.toJS(searchHit.geoEstates)
          );
        });
        return result;
      },
      makeAvailableOfflineWithDocuments = function (searchHit) {
        var documents = this.eventDocumentsOffline().reduce(function (a, c) {
          var docIds = c
            .documents()
            .filter(function (d) {
              return d.offline();
            })
            .map(function (d) {
              return d.documentId();
            });
          if (docIds.length) {
            a = a.concat(docIds);
          }
          return a;
        }, []);

        var result = getObjectAndCaseForOffline(
          searchHit.objectId(),
          searchHit.caseId()
        );
        documents.forEach(function (id) {
          result = result.then(function () {
            return dataapi.visit.getDocumentOffline(id);
          });
        });
        result = result.then(function () {
          return offlineCache.makeSearchHitAvailableOffline(
            searchHit.caseId(),
            ko.toJS(searchHit.plannedVisits),
            searchHit.controlResponsible(),
            searchHit.estateId(),
            ko.toJS(searchHit.geoEstates)
          );
        });
        return result;
      },
      getEventDocumentsForOffline = function (searchHit) {
        var self = this;
        self.eventDocumentsOffline.removeAll();
        return dataapi.visit
          .getCase(searchHit.caseId())
          .then(function (result) {
            if (!result || !result.data || !Array.isArray(result.data.events))
              return;

            var events = result.data.events.reduce(function (a, event) {
              event.documents = Array.isArray(event.documents)
                ? event.documents.filter(function (d) {
                    return d.hasDocument;
                  })
                : null;
              if (event.documents && event.documents.length) {
                a.push(event);
              }
              return a;
            }, []);

            utils.copyObjectIntoObservable(events, self.eventDocumentsOffline);

            var documents = result.data.events.filter(function (event) {
              return (
                Array.isArray(event.documents) && event.documents.length > 0
              );
            });
            return documents;
          });
      },
      searchUsingCache = function () {
        var self = this;
        return offlineCache.getCachedCases().then(function (cachedCases) {
          var searchResultType = self.searchResults.type;
          var searchResults = [];

          ko.utils.arrayForEach(cachedCases, function (item) {
            if (!item) return;

            var searchResult = new searchResultType()
              .objectId(item.case.objectId)
              .caseId(item.case.id)
              .dnr(item.case.dnr)
              .estateName(item.object.estateName)
              .address(item.object.address)
              .heading(item.case.description)
              .controlResponsible(item.controlResponsible)
              .estateId(item.estateId)
              .isCached(item.isCached)
              .hasVisit(item.hasVisit)
              .visit(item.visit);
            utils.copyObjectIntoObservable(
              item.plannedVisits,
              searchResult.plannedVisits
            );
            utils.copyObjectIntoObservable(
              item.geoEstates,
              searchResult.geoEstates
            );
            searchResults.push(searchResult);
          });

          dataapi.search.abort();
          self.searchResults(searchResults);
          self.generateVisitsForSearchHits();
          self.commit();

          return searchResults;
        });
      },
      abortSearch = dataapi.search.abort,
      estateGeosForSearchResults = function () {
        var result = [];
        this.searchResults().forEach(function (searchHit) {
          if (searchHit.geoEstates()) {
            result = result.concat(
              searchHit.geoEstates().map(function (estate) {
                return { type: "Feature", geometry: ko.toJS(estate) };
              })
            );
          }
        });
        return result;
      },
      estateGeosForSearchResult = function (searchResult) {
        if (!searchResult) return null;
        var result = null;
        // Only if search result is still part of all search results
        var present = this.searchResults().some(function (searchHit) {
          return searchHit.caseId() === searchResult.caseId();
        });
        if (present && searchResult.geoEstates()) {
          result = searchResult.geoEstates().map(function (estate) {
            return { type: "Feature", geometry: ko.toJS(estate) };
          });
        }
        return result;
      },
      init = function (beforeInit) {
        var self = this;
        return dataservice.Base.prototype.init
          .call(this, beforeInit)
          .then(function () {
            return dataapi.search.getMapOptions().then(function (response) {
              self.mapOptions(response.data);
            });
          });
      };

    return {
      generateVisitsForSearchHits: generateVisitsForSearchHits,
      searchUsingText: searchUsingText,
      removeCaseFromCache: removeCaseFromCache,
      removeVisitFromCache: removeVisitFromCache,
      makeAvailableOffline: makeAvailableOffline,
      makeAvailableOfflineWithDocuments: makeAvailableOfflineWithDocuments,
      getEventDocumentsForOffline: getEventDocumentsForOffline,
      searchUsingCache: searchUsingCache,
      abortSearch: abortSearch,
      estateGeosForAllSearchResults: estateGeosForSearchResults,
      estateGeosForSearchResult: estateGeosForSearchResult,
      init: init,
    };
  })()
);

dataservice.search = new dataservice.Search();
