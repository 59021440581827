var vm = vm || {};

vm.Login = function () {
    this.user = dataservice.login.loginInfo;
    this.hasPasswordFocus = ko.observable();
    this.loginError = ko.observable();
    this.user().isLoggedIn(true); // Logged in by default to prevent blinking login window

    this.user().username.subscribe(this.loginError.bind(this, false));
    this.user().password.subscribe(this.loginError.bind(this, false));
}

vm.Login.prototype.login = function () {
    var self = this;
    this.loginError(false);
    vm.global.isBusy(true);
    dataservice.login.login()
        .then(function () {
            vm.global.isBusy(false);
            self.hasPasswordFocus(self.user().username());
            if (!offlineCache.hasUnsyncedChanges()) {
                navigation.reloadCurrentView();
            }
        })
        .catch(function () {
            self.loginError(true);
            vm.global.isBusy(false);
            self.hasPasswordFocus(self.user().username());
        });
}

vm.Login.prototype.logout = function () {
    dataservice.login.logout();
}

vm.Login.prototype.logoutWithCheck = function () {
    var self = this;
    if (vm.global.isOnline()) {
        self.logout();
    } else {
        vm.confirmDialog.show("Applikationen är offline. Om du loggar ut nu kommer du inte kunna logga på förrän applikationen är online igen. Är du säker att du vill logga ut?")
            .then(function () {
                self.logout();
            });
    }
}

vm.Login.prototype.init = function () {
    var self = this;

    // Clear login errors and check if we're still logged in when going online/offline
    vm.global.isOnline.subscribe(function () {
        self.loginError(false);
        dataservice.login.getLoginInfo();
    });

    return new Promise(function (resolve, reject) {
        dataservice.login.getLoginInfo()
            .then(function () {
                // Resolve if already logged in
              if (self.user().isLoggedIn()) {
                resolve();
              } else if (self.user().authenticationMethod() === "ADFS" && !self.user().loggedOutManually()) {
                var hash = "" + location.hash;
                location.hash = "";
                location.search = "=" + new Date().getTime() + "&hash=" + encodeURIComponent(hash);
              } else if (self.user().authenticationMethod() === "SAML2" && !self.user().loggedOutManually()) {
                location = "api/saml2/login";
                } else {
                    // Resolve after successfull log in
                    var sub = self.user().isLoggedIn.subscribe(function (isLoggedIn) {
                        if (isLoggedIn === true) {
                            resolve();
                            sub.dispose();
                        }
                    });
                }
            })
            .catch(function (e) {
                reject(e);
            });
    });
}

vm.login = new vm.Login();