var vm = vm || {};

vm.VisitInformation = function () {
  vm.VisitBase.call(this);

  this.object = dataservice.visit.object;
  this.case = dataservice.visit.case;
  this.visitType = dataservice.visit.visitType;
  this.parties = ko
    .pureComputed(function () {
      return this.case()
        .parties()
    //    .reduce(function (acc, cur) {
    //      var index = acc.findIndex(function (p) {
    //        return p.id() === cur.id();
    //      });
    //      if (index >= 0) {
    //        acc[index].role(acc[index].role() + ", " + cur.role());
    //      } else {
    //        var p = new model.Party();
    //        utils.copyObjectIntoObservable(ko.toJS(cur), p);
    //        acc.push(p);
    //      }
    //      return acc;
    //    }, []);
    }, this)
    .extend({ type: model.Party });
};

vm.VisitInformation.prototype = Object.create(vm.VisitBase.prototype);
vm.VisitInformation.prototype.constructor = vm.VisitInformation;

vm.VisitInformation.prototype.getDocumentUrl = function (document) {
  return dataservice.visit.getDocumentUrl(document.documentId());
};

vm.VisitInformation.prototype.viewDocument = function (document) {
  function showDocument(data) {
    var content = "data:" + data.contentType + ";base64," + data.content;
    if (data.contentType === "application/pdf") {
      vm.pdfViewer.show(content, data.name);
    } else {
      var a = window.document.createElement("a");
      a.href = content;
      a.download = data.name;
      a.click();
    }
  }

  if (document.contentBase64()) {
    showDocument({
      contentType: document.contentBase64ContentType(),
      content: document.contentBase64(),
      name: document.contentBase64Filename(),
    });
  } else {
    vm.global.isBusy(true);
    dataservice.visit
      .getDocument(document.documentId())
      .then(function (x) {
        vm.global.isBusy(false);
        showDocument({
          contentType: x.data.contentType,
          content: x.data.content,
          name: x.data.name,
        });
      })
      .catch(function () {
        vm.global.isBusy(false);
        vm.global.addNotification(
          "Det gick inte att hämta, vänligen försök igen.",
          "error"
        );
      });
  }
};

vm.visitInformation = new vm.VisitInformation();
