var vm = vm || {};

vm.Search = function () {
    vm.Base.call(this);

    this.isBusySearching = ko.observable();
    this.useButton = config.search.useButton;
    this.onlineSubscription = null;

    this.caseWorkers = dataservice.search.caseWorkers;
    this.selectedCaseWorkers = dataservice.search.data().selectedCaseWorkers.extend({ title: "Handläggare" });
    this.searchText = dataservice.search.data().searchText.extend({ title: "Sökkriterier", rateLimit: { timeout: config.search.beginSearchTimeout, method: "notifyWhenChangesStop" }, minLength: { params: config.search.minChars, message: "Skriv minst {0} tecken för att söka" } });
    this.searchResults = dataservice.search.searchResults;
    this.selectedVisitType = ko.observable().extend({ title: "Välj besök" });
    this.expandedSearchResult = null;

    this.showOfflineHandlingarDialog = ko.observable();
    this.chosableOfflineHandlingar = dataservice.search.eventDocumentsOffline;
    this.searchHitForOfflineHandlingar = ko.observable();

    this.hasMarkedAllDocuments = ko.observable();
    this.hasMarkedAllDocuments.subscribe(function (val) {
        ko.utils.arrayForEach(this.chosableOfflineHandlingar(), function (x) {
            ko.utils.arrayForEach(x.documents(), function (doc) { doc.offline(val) });
        });
    }, this);

    // Perform search if search parameters change if no search button
    if (!this.useButton) {
        this.searchText.subscribe(this.searchUsingText, this);
        this.selectedCaseWorkers.subscribe(this.searchUsingText, this);
    }

    this.mapProps = ko.pureComputed(function () {
        var config = ko.toJS(dataservice.search.mapOptions);
        if (!config) return null;
        config.currentCenter = config.defaultCenter || [(config.maxExtent[0] + config.maxExtent[2]) / 2, (config.maxExtent[1] + config.maxExtent[3]) / 2];;
        config.currentZoom = 11;
        config.metersPerUnit = 1;
        config.error = function () { vm.global.addNotification("Det gick inte att ladda kartan", "error"); };
        return config;
    }, this);
    dataservice.search.mapOptions.subscribe(function () {
        if (this.isMapVisible()) {
            searchmap.init(ko.toJS(this.mapProps), "searchMap");
        }
    }, this);

    this.isMapEnabled = ko.pureComputed(function () {
        var config = this.mapProps();
        return config && config.enabled && vm.global.isOnline();
    }, this);

    this.isMapVisible = ko.observable();
    this.isMapVisible.subscribe(function (val) {
        if (val) {
            searchmap.init(ko.toJS(this.mapProps), "searchMap");
        }
    }, this);

}

vm.Search.prototype = Object.create(vm.Base.prototype);
vm.Search.prototype.constructor = vm.Search;

vm.Search.prototype.searchUsingText = function () {
    var self = this;
    self.isBusySearching(false);

    if (self.searchText() && self.searchText().length >= config.search.minChars && vm.global.isOnline()) {
        self.isBusySearching(true);
        return dataservice.search.searchUsingText()
            .then(function () {
                searchmap.updateEstates(dataservice.search.estateGeosForAllSearchResults());
                searchmap.updateSelectedEstate(dataservice.search.estateGeosForSearchResult(self.expandedSearchResult));
                self.isBusySearching(false);
                if (!self.searchResults().length) {
                    vm.global.addNotification("Inga ärenden hittades");
                }
            })
            .catch(function (response) {
                utils.logDebug(response);
                self.isBusySearching(false);
                if (response.status !== 0) {
                    vm.global.addNotification("Det gick inte att söka, vänligen försök igen.", "error");
                }
            });
    } else {
        return dataservice.search.searchUsingCache()
            .then(function () {
                searchmap.updateEstates(dataservice.search.estateGeosForAllSearchResults());
                searchmap.updateSelectedEstate(dataservice.search.estateGeosForSearchResult(self.expandedSearchResult));
            });
    }
}

vm.Search.prototype.openVisit = function (searchHit) {
    var routeParams = { caseId: searchHit.caseId(), visitTypeId: searchHit.selectedVisitType().visitTypeId(), isPlanned: searchHit.selectedVisitType().isPlanned() ? 1 : 0 };
    var queryParams = { search: 1 };
    navigation.navigateToView(config.searchTargetView, routeParams, queryParams);
};

vm.Search.prototype.continueVisit = function (searchHit) {
    var routeParams = { caseId: searchHit.caseId(), visitTypeId: searchHit.visit().visitType, isPlanned: searchHit.visit().isPlanned ? 1 : 0 };
    var queryParams = { search: 1 };
    navigation.navigateToView(config.searchTargetView, routeParams, queryParams);
};

vm.Search.prototype.removeVisit = function (searchHit) {
    var self = this;
    vm.confirmDialog.show("Är du säker på att du vill ta bort besöket?")
        .then(function () {
            dataservice.search.removeVisitFromCache(searchHit)
                .then(function () {
                    self.searchUsingText();
                });
        });
};

vm.Search.prototype.expandSearchResult = function (expanded, searchHit) {
    this.expandedSearchResult = expanded ? searchHit : null;
    searchmap.updateEstates(dataservice.search.estateGeosForAllSearchResults());
    searchmap.updateSelectedEstate(dataservice.search.estateGeosForSearchResult(this.expandedSearchResult));
};

vm.Search.prototype.makeAvailableOffline = function (searchHit) {
    var self = this;
    if (searchHit.isCached()) {
        var confirm = searchHit.hasVisit()
            ? vm.confirmDialog.show("Det finns ett besök på detta ärende som har inte skickats till ByggR än! Om du ta bort ärendet för offline användning tar du även bort besöket!")
            : (server.isOnline ? Promise.resolve() : vm.confirmDialog.show("Är du säker på att du vill ta bort ärendet för offline-användning? Du är inte online just nu och kan inte ladda ner ärendet igen förrän du är online igen."));
        confirm.then(function () {
            return dataservice.search.removeCaseFromCache(searchHit)
                .then(function () {
                    self.searchUsingText()
                        .then(function () {
                            searchHit.isCached(false);
                        });
                });
        });
    } else {
        vm.global.isBusy(true);
        dataservice.search.makeAvailableOffline(searchHit)
            .then(function () {
                vm.global.isBusy(false);
                searchHit.isCached(true);
            })
            .catch(function () {
                vm.global.isBusy(false);
                vm.global.addNotification("Det gick inte att hämta ärendet, vänligen försök igen.", "error");
            });
    }
}

vm.Search.prototype.makeAvailableOfflineWithDocuments = function () {
    var self = this;
    vm.global.isBusy(true);
    dataservice.search.makeAvailableOfflineWithDocuments(this.searchHitForOfflineHandlingar())
        .then(function () {
            vm.global.isBusy(false);
            self.searchHitForOfflineHandlingar().isCached(true);
            self.toggleCloseOfflineHandlingarDialog();
        })
        .catch(function () {
            vm.global.isBusy(false);
            vm.global.addNotification("Det gick inte att hämta ärendet, vänligen försök igen.", "error");
        });
}

vm.Search.prototype.toggleCloseOfflineHandlingarDialog = function (searchHit) {
    var self = this;
    this.searchHitForOfflineHandlingar(null);

    var isVisible = this.showOfflineHandlingarDialog();
    this.showOfflineHandlingarDialog(!isVisible);
    if (isVisible) return;

    vm.global.isBusy(true);
    dataservice.search.getEventDocumentsForOffline(searchHit)
        .then(function () {
            self.searchHitForOfflineHandlingar(searchHit);
            vm.global.isBusy(false);
        })
        .catch(function () {
            vm.global.isBusy(false);
            vm.global.addNotification("Det gick inte att hämta handlingarna, vänligen försök igen.", "error");
        });
}

vm.Search.prototype.rollback = function () {
    dataservice.search.abortSearch();
    this.searchText("");
    this.selectedCaseWorkers.removeAll();
    searchmap.updateEstates(null);
    searchmap.updateSelectedEstate(null);
    this.isMapVisible(false);
}

vm.Search.prototype.refresh = function () {
    dataservice.search.abortSearch();
    this.searchText("");
    this.selectedCaseWorkers.removeAll();
    this.initialized = false;

    return dataservice.search.uninit().then(this.init.bind(this));
}

vm.Search.prototype.init = function () {
    if (this.initialized) {
        dataservice.search.generateVisitsForSearchHits();
        this.searchUsingText();
        if (this.isMapVisible()) {
            searchmap.init(ko.toJS(this.mapProps), "searchMap");
        }
        return;
    }

    var self = this,
        beforeInit = function () {
            vm.global.isBusy(true);
            self.ready(false);
        };

    // Show cached objects if we go offline
    if (!self.onlineSubscription) {
        self.onlineSubscription = vm.global.isOnline.subscribe(function (online) {
            if (!online || !this.useButton)
                this.searchUsingText();
        }, self);
    }
    // Update search after sync
    if (!self.syncSubscription) {
        self.syncSubscription = vm.sync.syncResult.subscribe(function () {
            this.searchUsingText();
        }, self);
    }

    // Get data needed to search
    return dataservice.search.init(beforeInit)
        .then(function () {
            return tekis.components.init()
                .then(function () {
                    // Add logged in user as selected case worker
                    ko.utils.arrayForEach(self.caseWorkers(), function (cw) {
                        if (cw.username() === vm.login.user().username()) {
                            self.selectedCaseWorkers([cw.id()]);
                        }
                    });
                    self.initialized = true;
                    self.ready(true);
                    vm.global.isBusy(false);
                    self.searchUsingText();
                });
        })
        .catch(function (e) {
            vm.global.isBusy(false);
            vm.global.addNotification("Det gick inte att hämta grunddata som behövs för att kunna söka, vänligen försök igen.", "error");
            throw e;
        });
}

vm.search = new vm.Search();