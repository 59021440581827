var vm = vm || {};

vm.UploadVisits = function () {
    vm.Base.call(this);
    this.alwaysEditing(true);

    this.filedate = dataservice.uploadVisits.filedate;
    this.filename = dataservice.uploadVisits.filename;
    this.visits = dataservice.uploadVisits.visits;
    this.settings = dataservice.Base.data.settings;
    this.isUploading = ko.observable();

    this.notUploadedVisits = ko.pureComputed(function () {
        return this.visits().filter(function (visit) { return visit.uploadStatus() !== 2; });
    }, this);
    this.selectedVisits = ko.pureComputed(function () {
        return this.notUploadedVisits().filter(function (visit) { return visit.selectedForUpload(); });
    }, this);
}

vm.UploadVisits.prototype = Object.create(vm.Base.prototype);
vm.UploadVisits.prototype.constructor = vm.UploadVisits;

vm.UploadVisits.prototype.back = function () {
    navigation.navigateToView("settings");
}

vm.UploadVisits.prototype.visitTypeForId = function (visitTypeId) {
    return this.settings().settings().visitTypes().find(function (visitType) {
        return visitType.id() == visitTypeId; // Must be ==, visitTypeId is string
    });
}

vm.UploadVisits.prototype.fileAdded = function (element, data, event) {
    if (!element.value) return;
    var file = event.target.files[0];
    element.value = "";
    return dataservice.uploadVisits.loadFile(file)
        .catch(function (ex) {
            utils.log(ex);
            vm.global.addNotification("Det gick inte att läsa filen, försök igen.", "error");
        });
}

vm.UploadVisits.prototype.toggleForUpload = function (visit) {
    if (visit.uploadStatus() !== 2 && !this.isUploading()) visit.selectedForUpload(!visit.selectedForUpload());
}

vm.UploadVisits.prototype.uploadSelected = function () {
    var self = this,
        uploadedVisits = [],
        uploadPromise = Promise.resolve();
    this.isUploading(true);

    this.selectedVisits().forEach(function (visit) {
        uploadPromise = uploadPromise.then(function () {
            visit.uploadStatus(1);
            return dataservice.uploadVisits.uploadVisit(visit)
                .then(function () {
                    visit.uploadStatus(2);
                    uploadedVisits.push(visit);
                })
                .catch(function (e) {
                    utils.log(e);
                    visit.uploadStatus(3);
                });
        });
    });

    uploadPromise.then(function () {
        self.isUploading(false);
    });
}

vm.UploadVisits.prototype.toggleAll = function () {
    if (this.isUploading()) return;
    var allSelected = this.selectedVisits().length === this.notUploadedVisits().length;
    this.visits().forEach(function (visit) { visit.selectedForUpload(!allSelected); });
}

vm.UploadVisits.prototype.init = function () {
    var self = this,
        beforeInit = function () {
            self.ready(false);
            vm.global.isBusy(true);
        };
    return dataservice.uploadVisits.init(beforeInit)
        .then(function () {
            return tekis.components.init()
                .then(function () {
                    self.ready(true);
                    vm.global.isBusy(false);
                });
        })
        .catch(function (e) {
            vm.global.isBusy(false);
            vm.global.addNotification("Det gick inte att hämta data.", "error");
            throw e;
        });
}

vm.uploadVisits = new vm.UploadVisits();
