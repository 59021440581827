var dataapi = dataapi || {};

dataapi.login = {
    login: function (data) {
        return utils.query("api/user/login", {
            method: "POST",
            data: JSON.stringify(data)
        }, true);
    },
    logout: function () {
        return utils.query("api/user/logout", {
            method: "POST"
        }, true);
    },
    getLoginInfo: function () {
        return utils.query("api/user/info", null, true);
    }
}
