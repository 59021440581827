var model = model || {};

model.CaseWorker = function () {
    this.id = ko.observable();
    this.username = ko.observable();
    this.name = ko.observable();
    this.email = ko.observable();
    this.isActive = ko.observable();
    this.sign = ko.observable();

    this.fullName = ko.pureComputed(function () {
        return this.name();
    }, this);
    this.nameEmail = ko.pureComputed(function () {
        var email = this.email();
        return this.name() + (email && " <" + email + ">");
    }, this);
};

model.CaseWorker.prototype.toJSON = function () {
    var copy = ko.toJS(this);
    delete copy.fullName;
    delete copy.nameEmail;
    return copy;
};