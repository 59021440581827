var vm = vm || {};

vm.Settings = function () {
    vm.Base.call(this);
    var self = this;
    this.alwaysEditing(true);
    this.isValid = dataservice.settings.isValid;
    this.isDirty = dataservice.settings.isDirty;
    this.validationErrors = dataservice.settings.validationErrors;

    this.settings = dataservice.settings.data().settings;
    this.selectedVisitType = ko.observable();
    this.selectedVisitType.subscribe(function (val) {
        this.selectedControlArea(null);
        this.selectedNote(null);
    }, this);
    this.selectedControlArea = ko.observable();
    this.selectedControlQuestion = ko.observable();
    this.selectedControlArea.subscribe(function () { this.selectedControlQuestion(null); }, this);
    this.selectedNote = ko.observable();

    var getEventKindsForEventType = function (eventType) {
        var result = self.settings().baseData().eventTypes().find(function (type) {
            return type.name() === eventType;
        });
        return result && result.eventKinds() || [];
    };
    this.eventKindsForSelectedEventType = ko.pureComputed(function () {
        if (!this.selectedVisitType()) return null;
        return getEventKindsForEventType(this.selectedVisitType().eventType());
    }, this);
    this.hasEventKindsForSelectedEventType = ko.pureComputed(function () {
        var eventKinds = this.eventKindsForSelectedEventType();
        return eventKinds && eventKinds.length;
    }, this);
    this.eventKindsForSelectedEmailEventType = ko.pureComputed(function () {
        return getEventKindsForEventType(this.settings().settings().emailEventType());
    }, this);
    this.hasEventKindsForSelectedEmailEventType = ko.pureComputed(function () {
        var eventKinds = this.eventKindsForSelectedEmailEventType();
        return eventKinds && eventKinds.length;
    }, this);
}

vm.Settings.prototype = Object.create(vm.Base.prototype);
vm.Settings.prototype.constructor = vm.Settings;

vm.Settings.prototype.reportTemplateAdded = function (element, data, event) {
    var file = event.target.files[0],
        fileReader = new FileReader;
    if (!file) return;

    new Promise(function (resolve, reject) {
            try {
                fileReader.onload = function (e) {
                    var readerResult = e.target.result;
                    resolve(readerResult);
                };
                fileReader.readAsDataURL(file);
            } catch (ex) {
                // Cannot read file, fail
                reject(ex);
            }
        })
        .then(function (filedata) {
            dataservice.settings.addReportTemplate(file.name, filedata);
        })
        .catch(function (ex) {
            utils.log(ex);
            vm.global.addNotification("Det gick inte att läsa filen, vänligen försök igen.", "error");
        });

    element.value = "";
}

vm.Settings.prototype.downloadReportTemplate = function (reportTemplate) {
    if (reportTemplate.filedata() !== null) return;
    window.open(dataservice.settings.getReportTemplateUrl(reportTemplate.name()), "_blank");
}

vm.Settings.prototype.downloadReportTemplateVisible = function (reportTemplate) {
    return reportTemplate.filedata() === null;
}

vm.Settings.prototype.isActive = function (visitType) {
    return visitType.isActive();
}
vm.Settings.prototype.isNotActive = function (visitType) {
    return !visitType.isActive();
}

vm.Settings.prototype.copyVisitType = function (visitType) {
    var copy = dataservice.settings.copyVisitType(visitType);
    this.selectedVisitType(copy);
    this.validationErrors.showAllMessages();
}

vm.Settings.prototype.enableVisitType = function (visitType) {
    visitType.isActive(true);
}

vm.Settings.prototype.disableVisitType = function (visitType) {
    visitType.isActive(false);
}

vm.Settings.prototype.newVisitType = function () {
    var newVisitType = dataservice.settings.newVisitType();
    this.selectedVisitType(newVisitType);
    this.validationErrors.showAllMessages();
}

vm.Settings.prototype.removeVisitType = function (visitType) {
    var self = this;
    vm.confirmDialog.show("Är du säker på att du vill ta bort denna besökstyp?")
        .then(function () {
            self.settings().settings().visitTypes.remove(visitType);
            self.selectedVisitType(null);
        });
}

vm.Settings.prototype.newControlArea = function () {
    var newControlArea = new (this.selectedVisitType().controlAreas.type);
    this.selectedVisitType().controlAreas.push(newControlArea);
    this.selectedControlArea(newControlArea);
    this.validationErrors.showAllMessages();
}
vm.Settings.prototype.addedControlArea = function (newControlArea) {
    this.selectedControlArea(newControlArea);
    this.validationErrors.showAllMessages();
}
vm.Settings.prototype.removedControlArea = function (controlArea) {
    if (this.selectedControlArea() === controlArea) this.selectedControlArea(null);
}

vm.Settings.prototype.newControlQuestion = function () {
    var newControlQuestion = new (this.selectedControlArea().questions.type);
    this.selectedControlArea().questions.push(newControlQuestion);
    this.selectedControlQuestion(newControlQuestion);
    this.validationErrors.showAllMessages();
}
vm.Settings.prototype.addedControlQuestion = function (newControlQuestion) {
    this.selectedControlQuestion(newControlQuestion);
    this.validationErrors.showAllMessages();
}
vm.Settings.prototype.removedControlQuestion = function (controlQuestion) {
    if (this.selectedControlQuestion() === controlQuestion) this.selectedControlQuestion(null);
}

vm.Settings.prototype.newControlQuestionPhrase = function () {
    var phrase = new (this.selectedControlQuestion().phrases.type);
    this.selectedControlQuestion().phrases.push(phrase);
}

vm.Settings.prototype.newNote = function () {
    var newNote = new (this.selectedVisitType().notes.type);
    this.selectedVisitType().notes.push(newNote);
    this.selectedNote(newNote);
    this.validationErrors.showAllMessages();
}
vm.Settings.prototype.addedNote = function (newNote) {
    this.selectedNote(newNote);
    this.validationErrors.showAllMessages();
}
vm.Settings.prototype.removedNote = function (note) {
    if (this.selectedNote() === note) this.selectedNote(null);
}

vm.Settings.prototype.newNotePhrase = function () {
    var phrase = new (this.selectedNote().phrases.type);
    this.selectedNote().phrases.push(phrase);
}

vm.Settings.prototype.back = function () {
    navigation.navigateToView("search");
}

vm.Settings.prototype.rollback = function () {
    var self = this;
    return vm.Base.prototype.rollback.call(this)
        .then(function () {
            dataservice.settings.rollback();
            self.selectedVisitType(null);
        });
}

vm.Settings.prototype.commit = function () {
    var self = this;
    vm.global.isBusy(true);
    return dataservice.settings.updateSettings()
        .then(function () {
            return vm.Base.prototype.commit.call(self)
                .then(function () {
                    dataservice.settings.commit();
                    vm.global.addNotification("Inställningarna har sparats.", "success");
                    self.back();
                    vm.global.isBusy(false);
                });
        })
        .catch(function (e) {
            vm.global.isBusy(false);
            vm.global.addNotification("Det gick inte att spara, vänligen försök igen.", "error");
            throw e;
        });
}

vm.Settings.prototype.ifDirtyOnNavigationMessage = "Du har gjort ändringar som inte har sparats! Är du säker på att du vill lämna eller ladda om sidan?";
vm.Settings.prototype.canNavigateAway = function (newVm, isPageReload) {
    var canNavigate = !this.isDirty();
    if (!canNavigate && !isPageReload) {
        vm.global.addNotification("Du har gjort ändringar som du behöver spara eller ångra innan det går att byta vy.", "error");
    }
    return canNavigate;
}

vm.Settings.prototype.refresh = function () {
    return dataservice.settings.uninit().then(this.init.bind(this));
}

vm.Settings.prototype.init = function () {
    if (!vm.login.user().isAdmin()) throw new Error("User is not admin");

    this.selectedVisitType(null);

    var self = this,
        beforeInit = function () {
            vm.global.isBusy(true);
            self.ready(false);
        };

    return dataservice.settings.init(beforeInit)
        .then(function () {
            return tekis.components.init()
                .then(function () {
                    self.ready(true);
                    vm.global.isBusy(false);
                    self.validationErrors.showAllMessages();
                });
        })
        .catch(function (e) {
            vm.global.isBusy(false);
            vm.global.addNotification("Det gick inte att hämta inställningarna.", "error");
            throw e;
        });
}

vm.settings = new vm.Settings();