var Navigation = function () {
  this.activeView = ko.observable();
  this.activeViewParams = ko.observable();
  this.currentRoute = "";
  this.defaultView = { route: "" };

  this.views = [
    /*{
            id: only needed if no view
            name: display name of view
            view: name of view, used to identify the view, used to load template, used to match view in hash
            route: parameters for view, sammyjs syntax
            vm: views model of view. if contains init function it is called when view is activated
            dividerBefore/dividerAfter: for type == menu. adds divider to menu before/after
            type: navbar = show item in nav bar left side
                  navbarright = show item in nav bar right side
                  menu = show item in nav bar menu (menu item without route/action is shown as header)
                  modal = view is a modal
                  view = view that is not shown in nav
            defaultView: view that is shown if unknown or no view in hash
            icon: glyphicon to show with item
            action: javascript function called when item is selected
            actionThis: this for action function if no vm or different than vm
            enabled: bool or function that returns true/false if view/action should be enabled
            enabledThis: this for enabled function if different than vm
        },*/
    {
      name: "ConfirmDialog",
      view: "confirmDialog",
      vm: vm.confirmDialog,
      type: "modal",
    },
    {
      name: "Login",
      view: "login",
      vm: vm.login,
      type: "modal",
    },
    {
      name: "ImageViewer",
      view: "imageViewer",
      vm: vm.imageViewer,
      type: "modal",
    },
    {
      name: "PdfViewer",
      view: "pdfViewer",
      vm: vm.pdfViewer,
      type: "modal",
    },
    {
      name: "SyncDialog",
      view: "syncDialog",
      vm: vm.sync,
      type: "modal",
    },
    {
      name: "Sök",
      view: "search",
      route: "sok",
      vm: vm.search,
      defaultView: true,
      type: "menu",
      icon: "search",
      dividerAfter: true,
      enabledViews: ["logout", "settings"],
    },
    {
      id: "logout",
      name: ko.pureComputed(function () {
        return "Logga ut (" + vm.login.user().displayName() + ")";
      }),
      type: "menu",
      action: vm.login.logoutWithCheck,
      actionThis: vm.login,
    },
    {
      name: "Inställningar",
      view: "settings",
      route: "installningar",
      vm: vm.settings,
      type: "navbarright",
      icon: "cog",
      visible: function () {
        return vm.login.user().isAdmin() && vm.global.isOnline();
      },
      enabledViews: ["logout"],
    },
    {
      name: "Ladda upp besök",
      view: "uploadVisits",
      route: "laddauppbesok",
      vm: vm.uploadVisits,
      type: "navbarright",
      icon: "arrow-up",
      visible: function () {
        return vm.global.isOnline();
      },
      enabledViews: ["logout"],
    },
    {
      name: "Information",
      view: "visitInformation",
      route: "besok/:caseId/:visitTypeId/:isPlanned/information",
      vm: vm.visitInformation,
      type: "navbar",
      enabledViews: [
        "logout",
        "visitInformation",
        "visitNarvarande",
        "visitKontrollpunkter",
        "visitNotering",
        "visitHandlingar",
        "visitSammanstallning",
      ],
    },
    {
      name: "Närvarande",
      view: "visitNarvarande",
      route: "besok/:caseId/:visitTypeId/:isPlanned/narvarande",
      vm: vm.visitNarvarande,
      type: "navbar",
      enabledViews: [
        "logout",
        "visitInformation",
        "visitNarvarande",
        "visitKontrollpunkter",
        "visitNotering",
        "visitHandlingar",
        "visitSammanstallning",
      ],
    },
    {
      name: "Kontrollpunkter",
      view: "visitKontrollpunkter",
      route: "besok/:caseId/:visitTypeId/:isPlanned/kontrollpunkter",
      vm: vm.visitKontrollpunkter,
      type: "navbar",
      enabledViews: [
        "logout",
        "visitInformation",
        "visitNarvarande",
        "visitKontrollpunkter",
        "visitNotering",
        "visitHandlingar",
        "visitSammanstallning",
      ],
    },
    {
      name: "Noteringar",
      view: "visitNotering",
      route: "besok/:caseId/:visitTypeId/:isPlanned/notering",
      vm: vm.visitNotering,
      type: "navbar",
      enabledViews: [
        "logout",
        "visitInformation",
        "visitNarvarande",
        "visitKontrollpunkter",
        "visitNotering",
        "visitHandlingar",
        "visitSammanstallning",
      ],
    },
    {
      name: "Handlingar",
      view: "visitHandlingar",
      route: "besok/:caseId/:visitTypeId/:isPlanned/handlingar",
      vm: vm.visitHandlingar,
      type: "navbar",
      enabledViews: [
        "logout",
        "visitInformation",
        "visitNarvarande",
        "visitKontrollpunkter",
        "visitNotering",
        "visitHandlingar",
        "visitSammanstallning",
      ],
    },
    {
      name: "Sammanställning",
      view: "visitSammanstallning",
      route: "besok/:caseId/:visitTypeId/:isPlanned/sammanstallning",
      vm: vm.visitSammanstallning,
      type: "navbar",
      enabledViews: [
        "logout",
        "visitInformation",
        "visitNarvarande",
        "visitKontrollpunkter",
        "visitNotering",
        "visitHandlingar",
        "visitSammanstallning",
      ],
    },
  ];

  this.enabledViews = ko.pureComputed(function () {
    var self = this;
    var activeView = this.activeView();
    if (!activeView || !activeView.enabledViews) return [];

    var result = [];
    activeView.enabledViews.forEach(function (e) {
      var view = self.views.find(function (v) {
        return v.view === e || v.id === e;
      });
      if (view) {
        result.push(view);
      }
    });
    return result;
  }, this);
};

Navigation.prototype.loadTemplates = function () {
  var templatesFolder = config.templatesFolder
    ? config.templatesFolder.replace(/\/$/, "") + "/"
    : "";
  var result = Promise.resolve();
  this.views.forEach(function (view) {
    if (view.view) {
      view.isLoaded = ko.observable();
      result = result.then(function () {
        var templateUrl =
          templatesFolder +
          config.templateFilePrefix +
          view.view +
          config.templateFileSuffix;
        return utils.ajax(templateUrl).then(function (html) {
          var templateElement = document.createElement("script");
          templateElement.setAttribute("type", "text/html");
          templateElement.setAttribute("id", view.view + "Template");
          templateElement.innerHTML = html.data;
          document.body.appendChild(templateElement);
          view.isLoaded(true);
        });
      });
    }
  });
  return result;
};

Navigation.prototype.navigateToView = function (
  viewId,
  routeParams,
  queryParams
) {
  var view = this.views.find(function (v) {
    return v.id === viewId || v.view === viewId;
  });
  if (view && view.route) {
    var route = utils.replaceRouteParams(view.route, routeParams);
    if (queryParams && typeof queryParams === "object") {
      var queryString = "";
      for (var param in queryParams) {
        queryString += (queryString && "&") + param + "=" + queryParams[param];
      }
      route += "?" + queryString;
    }
    this.sammy.setLocation("#/" + route);
  }
};

Navigation.prototype.reloadCurrentView = function () {
  var activeView = this.activeView();
  if (!activeView || !activeView.vm) return Promise.resolve();
  if (typeof activeView.vm.refresh === "function")
    return activeView.vm.refresh();
  if (typeof activeView.vm.init === "function")
    return activeView.vm.init(this.activeViewParams());
};

Navigation.prototype.reload = function () {
  location.reload();
};

Navigation.prototype.run = function () {
  this.sammy.run();
};

Navigation.prototype.initRouting = function () {
  var self = this;
  this.sammy = new Sammy.Application();
  this.sammy.debug = utils.debug;

  this.views.forEach(function (view) {
    if (view.defaultView) self.defaultView = view;

    if (view.route && view.vm) {
      var hash = "#/?" + view.route + "/?";
      self.sammy.get(hash, function (context) {
        $(".dropdown.open .dropdown-toggle").dropdown("toggle"); // Close any drop downs when navigating, else they will stay open

        var initResult =
          typeof view.vm.init === "function" && view.vm.init(context.params);
        var initDone = function () {
            // Do not navigate to the new view if it is not enabled
            if (!view.enabled()) {
              sammy.setLocation(self.currentRoute || "#/" + self.defaultView);
              return;
            }
            document.title = config.applicationName + " - " + view.name;
            self.activeView(view);
            self.activeViewParams(context.params);
            self.currentRoute = self.sammy.getLocation();
            // Setup window unload to warn for unsaved changes on navigation
            window.onbeforeunload = undefined;
            if (
              view.vm.ifDirtyOnNavigationMessage &&
              (view.vm.canNavigateAway || view.vm.isDirty)
            ) {
              window.onbeforeunload = function () {
                var canUnload =
                  (view.vm.canNavigateAway &&
                    view.vm.canNavigateAway(null, true)) ||
                  (view.vm.isDirty && !view.vm.isDirty());
                return canUnload
                  ? undefined
                  : view.vm.ifDirtyOnNavigationMessage;
              };
            }
          },
          initFailed = function (e) {
            utils.log(e);
            if (self.currentRoute) {
              self.sammy.setLocation(self.currentRoute);
            }
          };
        initResult ? initResult.then(initDone, initFailed) : initDone();
      });
    }

    // If enabled function defined (view.enabled or vm.canNavigateTo if no enabled), make it an observable. If not view is always enabled
    if (typeof view.enabled === "boolean") {
      var enabledVal = view.enabled;
      view.enabled = function () {
        return enabledVal;
      };
    } else if (typeof view.enabled === "function")
      view.enabled = ko.pureComputed(view.enabled, view.enabledThis || view.vm);
    else if (view.vm && view.vm.canNavigateTo)
      view.enabled = ko.pureComputed(
        view.vm.canNavigateTo,
        view.enabledThis || view.vm
      );
    else
      view.enabled = function () {
        return true;
      };
    // Update view action to include view enabled check and correct this
    if (view.action) {
      var action = view.action;
      view.action = function () {
        var activeView = self.activeView();
        if (
          (!activeView ||
            !activeView.vm ||
            !activeView.vm.canNavigateAway ||
            activeView.vm.canNavigateAway(view.actionThis || view.vm)) &&
          view.enabled()
        )
          return action.call(view.actionThis || view.vm);
        else return false;
      };
    }
    // If visible function defined (view.visible or vm.isVisible if no visible), make it an observable. If not, navigations/action always visible
    if (typeof view.visible === "boolean") {
      var visibleVal = view.visible;
      view.visible = function () {
        return visibleVal;
      };
    } else if (typeof view.visible === "function")
      view.visible = ko.pureComputed(view.visible, view.visibleThis || view.vm);
    else if (view.vm && view.vm.isVisible)
      view.visible = ko.pureComputed(
        view.vm.isVisible,
        view.visibleThis || view.vm
      );
    else
      view.visible = function () {
        return true;
      };
  });
  this.sammy.before(
    /.*/,
    function (context) {
      var newView = this.views.find(function (view) {
        if (!view.route) return false;
        var route = view.route.replace(/:([\w\d]+)/g, "([^/]+)");
        var routeRegExp = new RegExp(route + "$");
        return routeRegExp.test(context.path);
      });
      var activeView = self.activeView();
      var result =
        (!activeView ||
          !activeView.vm ||
          !activeView.vm.canNavigateAway ||
          activeView.vm.canNavigateAway(newView && newView.vm)) &&
        (!newView ||
          !newView.vm ||
          !newView.vm.canNavigateTo ||
          newView.vm.canNavigateTo(activeView && activeView.vm));
      if (!result && self.currentRoute)
        self.sammy.setLocation(self.currentRoute);
      return result;
    }.bind(this)
  );
  this.sammy.get(/.*/, function () {
    var search = location.search.substring(1);
    var querystring = search.split("&");
    var hash = "";
    ko.utils.arrayForEach(querystring, function (x) {
      var parts = x.replace(/\?.*$/, "").split("=");
      if (parts.length === 2 && parts[0] === "hash") {
        hash = parts[1];
      }
    });
    self.sammy.setLocation(
      hash ? decodeURIComponent(hash) : "#/" + self.defaultView.route
    );
  });
};

Navigation.prototype.init = function () {
  var self = this;
  return this.loadTemplates().then(function () {
    self.initRouting();
  });
};

var navigation = new Navigation();
