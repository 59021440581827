var searchmap = function () {
    var
        estateSource = new ol.source.Vector(),
        selectedEstateSource = new ol.source.Vector(),
        myLocationSource = new ol.source.Vector(),
        highlightedEstates = null,
        highlightedSelectedEstate = null,
        map = null,

        updateLayer = function (vectorSource, geoJsonFeatures) {
            vectorSource.clear();
            if (!geoJsonFeatures) return;
            var parser = new ol.format.GeoJSON();
            var features = geoJsonFeatures.map(function (geoJson) {
                return parser.readFeature(geoJson);
            });
            vectorSource.addFeatures(features);
        },
        init = function (props, elementId) {
            if (!proj4.defs(props.crs)) {
                proj4.defs(props.crs, props.crsWkt);
            }

            var element = document.getElementById(elementId);
            // Reinit until map has a size so map initializes completely (fetches background image)
            if (!element || element.getBoundingClientRect().width === 0) {
                setTimeout(searchmap.init.bind(this, props, elementId), 200);
                return;
            }

            var estateStyle = new ol.style.Style({
                fill: new ol.style.Fill({ color: "rgba(0,95,211,0.5)" }),
                //stroke: new ol.style.Stroke({ color: "white", width: 1.5 })
            });
            var selectedEstateStyle = new ol.style.Style({
                fill: new ol.style.Fill({ color: "rgba(200,16,46,0.5)" }),
                //stroke: new ol.style.Stroke({ color: "white", width: 1.5 })
            });
            var myLocationStyle = new ol.style.Style({
                text: new ol.style.Text({
                    font: "32px Glyphicons Halflings",
                    text: "\ue087",
                    textAlign: "center",
                    textBaseline: "middle",
                    fill: new ol.style.Fill({ color: "rgb(72,168,0)" }),
                    stroke: new ol.style.Stroke({ color: "white", width: 1.5 })
                })
            });

            var backgroundImageProps = {
                projection: props.crs,
                url: props.mapGuideProtocol + props.mapGuideUrl,
                useOverlay: false,
                metersPerUnit: props.metersPerUnit,
                params: {
                    USERNAME: props.mapGuideUsername || "Anonymous",
                    PASSWORD: props.mapGuidePassword || "",
                    VERSION: props.mapGuideVersion,
                    SERVICE: props.serverService
                },
                ratio: 2,
                events: { "imageloaderror": function () { utils.logConsole(arguments); } }
            };
            var imageLayerSource;
            if (props.type === "WMS") {
                backgroundImageProps.params.LAYERS = props.mapGuideMapDefinition;
                if (props.mapGuideBasicAuth) {
                    backgroundImageProps.imageLoadFunction = function customLoader(image, src) {
                        var xhr = new XMLHttpRequest();
                        xhr.open('GET', src, true);
                        xhr.responseType = 'blob';
                        xhr.setRequestHeader("Authorization", "Basic " + props.mapGuideBasicAuth),
                            xhr.onreadystatechange = function () {
                                if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
                                    var reader = new FileReader();
                                    reader.onloadend = function () {
                                        image.getImage().src = reader.result;
                                    }
                                    reader.readAsDataURL(xhr.response);
                                }
                            };
                        xhr.send();
                    }
                }
                imageLayerSource = new ol.source.ImageWMS(backgroundImageProps);
            } else {
                backgroundImageProps.params.MAPDEFINITION = props.mapGuideMapDefinition;
                backgroundImageProps.params.FORMAT = "PNG";
                imageLayerSource = new ol.source.ImageMapGuide(backgroundImageProps);
            }

            while (element.firstChild) {
                element.removeChild(element.firstChild);
            }
            map = new ol.Map({
                controls: [],
                target: element,
                layers: [
                    new ol.layer.Image({
                        extend: props.maxExtent || [],
                        source: imageLayerSource
                    }),
                    new ol.layer.Vector({
                        source: estateSource,
                        style: estateStyle
                    }),
                    new ol.layer.Vector({
                        source: selectedEstateSource,
                        style: selectedEstateStyle
                    })
                ],
                view: new ol.View({
                    projection: props.crs,
                    center: props.currentCenter,
                    zoom: props.currentZoom
                })
            });
            if (typeof props.error === "function") {
                map.getLayers().getArray()[0].getSource().on("imageloaderror", props.error);
            }

            highlightedEstates && updateEstates(highlightedEstates);
            highlightedSelectedEstate && updateSelectedEstate(highlightedSelectedEstate);
        },
        setCurrentPosition = function (point) {
            updateLayer(myLocationSource, point ? [{ type: "Feature", geometry: { type: "Point", coordinates: point } }] : []);
        },
        centerMap = function (point) {
            map.getView().setCenter(point);
        },
        zoomMap = function (zoom) {
            map.getView().setZoom(zoom);
        },
        resize = function () {
            if (map) map.updateSize();
        },
        updateEstates = function (estates) {
            highlightedEstates = estates;
            if (map) {
                updateLayer(estateSource, highlightedEstates);
                var extent = estateSource.getExtent();
                if (extent && extent[0] !== Infinity) {
                    map.getView().fit(extent, map.getSize(), { padding: [20, 20, 20, 20] });
                }
            }
        },
        updateSelectedEstate = function (estate) {
            highlightedSelectedEstate = estate;
            if (map) {
                updateLayer(selectedEstateSource, highlightedSelectedEstate);
                var extent = selectedEstateSource.getExtent();
                if (extent && extent[0] !== Infinity) {
                    map.getView().fit(extent, map.getSize(), { padding: [100, 100, 100, 100] });
                }
            }
        };

    return {
        init: init,
        centerMap: centerMap,
        zoomMap: zoomMap,
        resize: resize,
        setCurrentPosition: setCurrentPosition,
        updateEstates: updateEstates,
        updateSelectedEstate: updateSelectedEstate
    }
}();
