var dataservice = dataservice || {};

dataservice.Login = function () {
    dataservice.Base.call(this);
    this.loginInfo = ko.observable().extend({ type: model.User });
    this.loginInfo(new (this.loginInfo.type));

    var self = this;
    // Log out when ping returns false
    utils.ajaxCallbacks.push({
        url: config.serverPingOptions.url,
        success: function (data) { if (data === false) { self.logoutLocally(); } }
    });
    // Log out when any ajax call returns 401
    utils.ajaxCallbacks.push({
        error: function (xhr) { if (xhr && xhr.status === 401) { self.logoutLocally(); } }
    });
}

dataservice.Login.prototype = Object.create(dataservice.Base.prototype);
dataservice.Login.prototype.constructor = dataservice.Login;

utils.extend(dataservice.Login.prototype, function () {
    var
        logoutLocally = function (manually) {
            this.loginInfo().isLoggedIn(false);
            this.loginInfo().displayName("");
            this.loginInfo().isAdmin(false);
            this.loginInfo().loggedOutManually(manually || false);
        },
        getLoginInfo = function () {
            return this.getData(dataapi.login.getLoginInfo, null, this.loginInfo).catch(this.logoutLocally.bind(this));
        },
        login = function () {
            var self = this;
            var credentials = { username: this.loginInfo().username(), password: this.loginInfo().password(), rememberMe: this.loginInfo().rememberMe() };
            return dataapi.login.login(credentials)
                .then(function () {
                    self.loginInfo().password(undefined);
                    self.loginInfo().rememberMe(false);
                    return self.getLoginInfo();
                })
                .catch(function (e) {
                    self.logoutLocally();
                    throw e;
                });
        },
        logout = function () {
            var self = this;
            return dataapi.login.logout()
                .then(function () {
                    self.logoutLocally(true);
                });
        };

    return {
        logoutLocally: logoutLocally,
        getLoginInfo: getLoginInfo,
        login: login,
        logout: logout
    };
}());

dataservice.login = new dataservice.Login();
