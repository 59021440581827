var vm = vm || {};

vm.sync = (function () {

    var
        step = ko.observable(),
        total = ko.observable(),
        isBusy = ko.observable().extend({ rateLimit: 100 }),
        showAdvanced = ko.observable(),
        syncResult = ko.observable(),
        unsyncedChangesData = ko.observable(),
        unsyncedChangesFilename = ko.observable(),
        showSyncDialog = ko.pureComputed(function () {
            if (offlineCache.hasUnsyncedChanges()) {
                step(0);
                total(0);
                syncResult(undefined);
                showAdvanced(false);
                offlineCache.getUnsyncedChanges()
                    .then(function (changes) {
                        var data = { date: moment().format("YYYY-MM-DD HH:mm:ss"), changes: changes };
                        unsyncedChangesData("data:application/json;charset=utf-8," + encodeURIComponent(JSON.stringify(data, null, "\t").replace(/\n/g, "\r\n")));
                    });
                unsyncedChangesFilename("PlatsbesokSynk" + moment().format("YYYYMMDDHHmmss") + ".txt");
            } else {
                unsyncedChangesData(undefined);
                unsyncedChangesFilename(undefined);
            }
            return (vm.global.isOnline() && offlineCache.hasUnsyncedChanges()) || syncResult() !== undefined;
        }),
        discard = function () {
            vm.confirmDialog.show("Är du säker på att du vill ta bort alla ändringar och tömma cachen?")
                .then(function () {
                    offlineCache.removeAll()
                        .then(function () {
                            navigation.reload();
                        });
                });
        },
        sync = function () {
            isBusy(true);
            offlineCache.syncChanges(step, total)
                .then(function () {
                    syncResult(true);
                    isBusy(false);
                }, function () {
                    syncResult(false);
                    isBusy(false);
                });
        },
        toggleShowAdvanded = function () {
            showAdvanced(!showAdvanced());
        };

    return {
        step: step,
        total: total,
        isBusy: isBusy,
        showAdvanced: showAdvanced,
        syncResult: syncResult,
        unsyncedChangesData: unsyncedChangesData,
        unsyncedChangesFilename: unsyncedChangesFilename,
        showSyncDialog: showSyncDialog,
        discard: discard,
        sync: sync,
        toggleShowAdvanded: toggleShowAdvanded
    };

})();
