var dataservice = dataservice || {};

dataservice.Base = function () {
    this.data = ko.validatedObservable({}, { deep: true, observable: true, live: true });

    this.isValid = this.data.isValid;
    this.validationErrors = this.data.errors;
    if (utils.debug) {
        this.validationErrors.subscribe(function () {
            var errors = ko.validation.utils.propertiesWithValidationErrors(this.data);
            errors.length && utils.log(errors);
        }, this);
    }

    this.originalDataJs = null;
    this.originalDataJson = null;
    this.isDirtyFlag = ko.observable();
    this.isDirty = ko.computed(function () {
        if (this.isDirtyFlag() !== true) {
            this.isDirtyFlag(this.originalDataJson !== ko.toJSON(this.data));
        }
        return this.isDirtyFlag();
    }, this);
}

// Static/shared data
dataservice.Base.data = {};
dataservice.Base.data.settings = ko.observable().extend({ type: model.Settings });
dataservice.Base.data.caseWorkers = ko.observableArray().extend({ type: model.CaseWorker });

dataservice.Base.prototype.getData = function (getApiFunc, id, data, resolveOnNotFound) {
    var self = this;

    var isArray = ko.validation.utils.isObservableArray(data);
    if (isArray)
        data.removeAll();

    var params = ko.toJS(id);
    return getApiFunc.apply(this, Array.isArray(params) ? params : [params])
        .then(function (response) {
            if (!isArray && !data())
                data(new (data.type));
            self.copyObjectIntoObservable(response.data, data);
            return response;
        })
        .catch(function (response) {
            if (!isArray)
                data(undefined);
            if (resolveOnNotFound && response && response.status === 404)
                return Promise.resolve();
            throw response;
        });
}

dataservice.Base.prototype.copyObjectIntoObservable = utils.copyObjectIntoObservable;

dataservice.Base.prototype.commit = function () {
    this.data.valueHasMutated();
    this.originalDataJson = ko.toJSON(this.data);
    this.originalDataJs = ko.toJS(this.data);
    this.isDirtyFlag(false);
}

dataservice.Base.prototype.rollback = function () {
    this.copyObjectIntoObservable(this.originalDataJs, this.data);
    this.data.valueHasMutated();
    this.isDirtyFlag(false);
}

dataservice.Base.prototype.uninit = function () {
    dataservice.Base.initialized = false;
    return Promise.resolve();
}

dataservice.Base.prototype.init = function (beforeInit) {
    if (dataservice.Base.initialized) {
        return Promise.resolve(false); // false = no init needed
    }

    var self = this,
        result = Promise.resolve();

    if (typeof beforeInit === "function") {
        result = result.then(function () { return beforeInit(); });
    }
    result = result.then(function () { return self.getData(dataapi.settings.getSettings, null, dataservice.Base.data.settings) });
    result = result.then(function () { return self.getData(dataapi.search.getCaseWorkers, null, dataservice.Base.data.caseWorkers) });
    result.then(function () {
        dataservice.Base.initialized = true;
        self.commit();
        return true; // true = init was needed
    }, function (e) {
        self.commit();
        throw e;
    });

    return result;
}
