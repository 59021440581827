var dataservice = dataservice || {};

dataservice.UploadVisits = function () {
    dataservice.Base.call(this);

    // Data that we should be able to rollback and/or check dirty state of
    //this.data().visit = ko.observable().extend({ type: model.Visit });

    // Other data
    this.filedate = ko.observable();
    this.filename = ko.observable();
    this.visits = ko.observableArray().extend({ type: model.Visit });
}

dataservice.UploadVisits.prototype = Object.create(dataservice.Base.prototype);
dataservice.UploadVisits.prototype.constructor = dataservice.UploadVisits;

utils.extend(dataservice.UploadVisits.prototype, function () {
    var
        loadVisits = function (data) {
            this.filedate(data.date);
            this.filename(data.filename);

            var changesThatAreVisits = data.changes.filter(function (data) {
                return data.url.indexOf("visitreports") !== -1;
            });
            var visits = changesThatAreVisits.map(function (visitData) {
                visitData.data.selectedForUpload = true;
                visitData.data.uploadStatus = 0;
                return visitData.data;
            });
            utils.copyObjectIntoObservable(visits, this.visits);
            this.visits.sort(function (a, b) {
                if (a.date() === b.date()) return 0;
                return a.date() < b.date() ? -1 : 1;
            });
        },
        loadFile = function (file) {
            var self = this;
            return new Promise(function (resolve, reject) {
                var fileReader = new FileReader;
                try {
                    fileReader.onload = function (e) {
                        var dataAsObject = JSON.parse(e.target.result);
                        dataAsObject.filename = file.name;
                        loadVisits.call(self, dataAsObject);
                        resolve();
                    };
                    fileReader.readAsText(file);
                } catch (ex) {
                    // Cannot read file, fail
                    reject(ex);
                }
            });
        },

        uploadVisit = function (visit) {
            visit.reportEmail = {};
            return dataapi.visit.uploadVisit(ko.toJS(visit));
        },

        init = function (beforeInit) {
            this.filedate(null);
            this.filename(null);
            this.visits.removeAll();
            return dataservice.Base.prototype.init.call(this, beforeInit);
        };

    return {
        loadFile: loadFile,
        uploadVisit: uploadVisit,
        init: init
    };
}());

dataservice.uploadVisits = new dataservice.UploadVisits();