var dataapi = dataapi || {};

dataapi.search = function () {
    var currentSearchRequest,
        usingText = function (text, selectedCaseWorkers) {
            var data = { search: text };
            data.caseworkers = selectedCaseWorkers.join(",") || "";
            currentSearchRequest = utils.query("api/search", {
                data: data
            });
            return currentSearchRequest;
        },
        abort = function () {
            currentSearchRequest && currentSearchRequest.abort && currentSearchRequest.abort();
            currentSearchRequest = null;
        },
        getCaseWorkers = function () {
            return utils.query("api/caseworkers");
        },
        getMapOptions = function () {
            return utils.query("api/mapoptions");
        };

    return {
        usingText: usingText,
        abort: abort,
        getCaseWorkers: getCaseWorkers,
        getMapOptions: getMapOptions
    };
}();
