var model = model || {};

model.SearchHit = function () {
    this.objectId = ko.observable().extend({ title: "Object ID", integer: true });
    this.caseId = ko.observable().extend({ title: "Case ID", integer: true });
    this.dnr = ko.observable().extend({ title: "DNR" });
    this.estateName = ko.observable().extend({ title: "Fastighet" });
    this.address = ko.observable().extend({ title: "Adress" });
    this.heading = ko.observable().extend({ title: "Ärendemening" });
    this.controlResponsible = ko.observable().extend({ title: "Kontrollansvarig" });
    this.plannedVisits = ko.observableArray().extend({ title: "Planerade besök", type: model.PlannedVisit });
    this.estateId = ko.observable();
    this.geoEstates = ko.observableArray().extend({ type: model.Geo });
    //this.distanceToDevice = ko.observable().extend({ title: "Avstånd", number: true });

    // Client use only
    this.visits = ko.observableArray().extend({ type: model.SearchHitVisit });
    this.visit = ko.observable();
    this.selectedVisitType = ko.observable().extend({ title: "Välj besök" });
    this.isCached = ko.observable();
    this.hasVisit = ko.observable();
};

model.PlannedVisit = function () {
    this.watchType = ko.observable();
    this.caseWorkerId = ko.observable().extend({ integer: true });
    this.date = ko.observable();
    this.note = ko.observable();
};

model.SearchHitVisit = function () {
    this.visitTypeId = ko.observable();
    this.isPlanned = ko.observable();
    this.description = ko.observable().extend({ title: "Beskrivning" });
}

model.Geo = function () {
    this.type = ko.observable();
    this.coordinates = ko.observableArray().extend({ type: Number }); // Can be nested arrays but we don't care about that
}

model.EventDocumentsOffline = function () {
    this.id = ko.observable();
    this.caption = ko.observable();
    this.date = ko.observable();
    this.documents = ko.observableArray().extend({ type: model.EventDocumentOffline });
    //caseId
}

model.EventDocumentOffline = function () {
    this.id = ko.observable();
    this.caption = ko.observable();
    this.date = ko.observable();
    this.documentId = ko.observable();
    this.documentTypeDescription = ko.observable();
    this.offline = ko.observable().extend({ title: "Offline" });

    this.description = ko.pureComputed(function () {
        var caption = this.caption(),
            type = this.documentTypeDescription();
        return caption ? type + " - " + caption : type;
    }, this);
}
