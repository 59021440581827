var vm = vm || {};

vm.VisitBase = function () {
  vm.Base.call(this);
  this.alwaysEditing(true);
  this.isValid = dataservice.visit.isValid;
  this.isDirty = dataservice.visit.isDirty;
  this.validationErrors = dataservice.visit.validationErrors;

  this.visit = dataservice.visit.data().visit;
};

vm.VisitBase.prototype = Object.create(vm.Base.prototype);
vm.VisitBase.prototype.constructor = vm.VisitBase;

vm.VisitBase.prototype.rollback = function () {
  return vm.Base.prototype.rollback.call(this).then(function () {
    dataservice.visit.rollback();
  });
};

vm.VisitBase.prototype.commit = function () {
  return vm.Base.prototype.commit.call(this).then(function () {
    dataservice.visit.commit();
  });
};

vm.VisitBase.prototype.ifDirtyOnNavigationMessage =
  "Tillsynen kommer att avbrytas om du lämnar eller laddar om sidan! Är du säker på att du vill lämna eller ladda om sidan?";
vm.VisitBase.prototype.canNavigateAway = function (newVm, isPageReload) {
  if (newVm instanceof vm.VisitBase) return true; // Allow navigation if new view model is also vm.VisitBase
  dataservice.visit.unload();
  return true;
};

vm.VisitBase.prototype.isVisible = function () {
  if (!dataservice.visit.visitType()) return true;
  var views = [
    vm.VisitNarvarande,
    vm.VisitKontrollpunkter,
    vm.VisitNotering,
    vm.VisitHandlingar,
  ];
  return views.every(function (view, index) {
    return (
      !(this instanceof view) ||
      (dataservice.visit.visitType().enabledViews() & Math.pow(2, index)) ===
        Math.pow(2, index)
    );
  }, this);
};

vm.VisitBase.prototype.backToSearch = function () {
  navigation.navigateToView(config.closeVisitTargetView);
};

vm.VisitBase.prototype.discardVisit = function () {
  var self = this;
  vm.confirmDialog
    .show(
      "Om du avbryter besöket kommer alla svar, noteringar och handlingar att tas bort.<br/><br/>Är du säker på att du vill avbryta besöket?",
      "Avbryt besöket?"
    )
    .then(function () {
      dataservice.visit.removeCachedVisit(self.visit().dnr()).then(function () {
        vm.global.addNotification("Besöket har avbrutits.", "info");
        navigation.navigateToView(config.closeVisitTargetView);
      });
    });
};

vm.VisitBase.prototype.refresh = function () {
  var self = this;
  self.ready(false);
  vm.global.isBusy(true);
  return dataservice.visit
    .refresh()
    .then(function () {
      self.ready(true);
      vm.global.isBusy(false);
    })
    .catch(function (e) {
      vm.global.isBusy(false);
      vm.global.addNotification("Det gick inte att hämta data.", "error"); // TODO better message
      throw e;
    });
};

vm.VisitBase.prototype.toggleTempNote = function () {
  var self = this;
  self.visit().showTempNote(!self.visit().showTempNote());
};

vm.VisitBase.prototype.init = function (params) {
  var self = this,
    beforeInit = function () {
      self.ready(false);
      vm.global.isBusy(true);
    };

  // Clear send email when going offline, else if checked user cannot save offline
  if (!self.clearSendEmailSubscription) {
    self.clearSendEmailSubscription = vm.global.isOnline.subscribe(function (
      online
    ) {
      if (!online) dataservice.visit.visitReportEmail().send(false);
    });
  }

  return dataservice.visit
    .init(
      params.caseId,
      params.visitTypeId,
      params.isPlanned,
      params.search,
      beforeInit
    )
    .then(function () {
      return tekis.components.init().then(function () {
        self.ready(true);
        vm.global.isBusy(false);
      });
    })
    .catch(function (e) {
      vm.global.isBusy(false);
      vm.global.addNotification("Det gick inte att hämta data.", "error"); // TODO better message
      throw e;
    });
};
