var vm = vm || {};

vm.VisitNarvarande = function () {
  vm.VisitBase.call(this);

  this.visitContacts = dataservice.visit.data().visitContacts;
  this.newContact = ko
    .validatedObservable(undefined, {
      deep: true,
      observable: true,
      live: true,
    })
    .extend({ type: model.Party });
  this.editContact = ko.validatedObservable(undefined, {
    deep: true,
    observable: true,
    live: true,
  });

  this.selectableContacts = ko.pureComputed(function () {
    var contacts = this.visitContacts();
    return ko.utils.arrayFilter(
      dataservice.visit.case().parties(),
      function (objContact) {
        return !ko.utils.arrayFirst(contacts, function (contact) {
          return (
            contact.id() === objContact.id()
          );
        });
      }
    );
  }, this);
};

vm.VisitNarvarande.prototype = Object.create(vm.VisitBase.prototype);
vm.VisitNarvarande.prototype.constructor = vm.VisitNarvarande;

vm.VisitNarvarande.prototype.addExistingContact = function (contact) {
  var copy = new model.Party();
  utils.copyObjectIntoObservable(ko.toJS(contact), copy);
  this.visitContacts.push(copy);
};

vm.VisitNarvarande.prototype.addNewContact = function () {
  this.visitContacts.push(this.newContact());
  this.newContact(new this.newContact.type());
};

vm.VisitNarvarande.prototype.removeContact = function (contact) {
  this.visitContacts.remove(contact);
  if (this.editContact() === contact) this.editContact(undefined);
};

vm.VisitNarvarande.prototype.editCustomContact = function (contact) {
  this.editOriginal = ko.toJS(contact);
  this.editContact(contact);
};

vm.VisitNarvarande.prototype.replaceEditContact = function () {
  this.editContact(undefined);
};

vm.VisitNarvarande.prototype.cancelEditContact = function () {
  this.editContact().name(this.editOriginal.name);
  this.editContact().role(this.editOriginal.role);
  this.editContact().telephone(this.editOriginal.telephone);
  this.editContact().email(this.editOriginal.email);
  this.editContact(undefined);
};

vm.VisitNarvarande.prototype.init = function (params) {
  this.newContact(new this.newContact.type());
  return vm.VisitBase.prototype.init.call(this, params);
};

vm.visitNarvarande = new vm.VisitNarvarande();
