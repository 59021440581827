var dataservice = dataservice || {};

dataservice.Visit = function () {
  dataservice.Base.call(this);
  var self = this;

  // Params
  this.caseId = ko.observable();
  this.visitTypeId = ko.observable();
  this.isPlanned = ko.observable();

  // Data that we should be able to rollback and/or check dirty state of
  this.data().visit = ko.observable().extend({ type: model.Visit });
  this.data().visitContacts = ko
    .observableArray()
    .extend({ type: model.Party });

  // Other data
  this.object = ko.observable().extend({ type: model.Object });
  this.case = ko.observable().extend({ type: model.Case });
  this.settings = dataservice.Base.data.settings;
  this.visitType = ko.observable();
  this.visitReportEmail = ko
    .observable(new model.VisitReportEmail())
    .extend({ type: model.VisitReportEmail });
  this.caseWorkers = ko.pureComputed(function () {
    return dataservice.Base.data.caseWorkers().filter(function (caseWorker) {
      var isActive = this.settings().settings().showInactive();
      return caseWorker.isActive() || isActive;
    }, this);
  }, this);

  this.possibleProtocolRecipients = ko.pureComputed(function () {
    var visitContacts = this.data()
      .visitContacts()
      .filter(function (contact) {
        return contact.email();
      });
    // Add not present parties with email
    visitContacts = visitContacts.concat(
      this.case()
        .parties()
        .filter(function (contact) {
          var exists = visitContacts.some(function (c) {
            return (
              c.id() === contact.id() ||
              (c.id() === 0 && c.name() === contact.name())
            );
          });
          return !exists && contact.email();
        })
    );

    // One entry for each email address
    var result = [];
    visitContacts.forEach(function (contact) {
      var emails = contact
        .email()
        .replace(/,/g, ";")
        .split(";")
        .map(function (e) {
          return e.trim();
        })
        .filter(function (e) {
          return e;
        });
      emails.forEach(function (email) {
        // No duplicates
        if (
          result.some(function (r) {
            return r.email() === email;
          })
        )
          return;

        var newContact = new (this.data().visitContacts.type)();
        contact = ko.toJS(contact);
        contact.email = email;
        utils.copyObjectIntoObservable(contact, newContact);
        result.push(newContact);
      }, this);
    }, this);

    return result.sort(function (a, b) {
      var aa = (a.name() + a.email()).toLowerCase(),
        bb = (b.name() + b.email()).toLowerCase();
      if (aa < bb) return -1;
      if (aa > bb) return 1;
      return 0;
    });
  }, this);
};

dataservice.Visit.prototype = Object.create(dataservice.Base.prototype);
dataservice.Visit.prototype.constructor = dataservice.Visit;

utils.extend(
  dataservice.Visit.prototype,
  (function () {
    var getCase = function () {
        return this.getData(dataapi.visit.getCase, this.caseId, this.case);
      },
      getObject = function () {
        return this.getData(
          dataapi.visit.getObject,
          this.case().objectId,
          this.object
        );
      },
      getDocumentUrl = function (id) {
        return dataapi.visit.getDocumentUrl(id);
      },
      getDocument = function (id) {
        return dataapi.visit.getDocument(id);
      },
      createNewVisit = function (existingVisit) {
        var self = this;
        var controlAreas;

        this.data().visit(new (this.data().visit.type)());
        this.data().visit().caseWorker(new model.CaseWorker());
        this.data().visitContacts([]);
        this.data().visit().date(new moment().format("YYYY-MM-DD"));
        this.data().visit().dnr(this.case().dnr());
        this.data().visit().isPlanned(this.isPlanned());
        this.data().visit().visitType(this.visitType().id());

        if (existingVisit) {
          this.copyObjectIntoObservable(
            existingVisit.contacts,
            this.data().visitContacts
          );

          var existingNotes = existingVisit.notes;
          existingVisit.notes = [];
          var existingControlAreas = existingVisit.controlAreas;
          existingVisit.controlAreas = [];

          this.copyObjectIntoObservable(existingVisit, this.data().visit);

          if (this.visitType().controlAreas().length) {
            controlAreas = this.visitType()
              .controlAreas()
              .map(function (controlArea) {
                var existingControlArea = existingControlAreas.find(function (
                  x
                ) {
                  return x.category === controlArea.category();
                });
                var controlAreaWithResult = new (self
                  .data()
                  .visit().controlAreas.type)();
                controlAreaWithResult.category(controlArea.category());
                controlAreaWithResult.questions(
                  controlArea.questions().map(function (item) {
                    var result = new controlAreaWithResult.questions.type();
                    var theItem = item;
                    if (existingControlArea) {
                      var existingQuestion = existingControlArea.questions.find(
                        function (x) {
                          return x.question === item.question();
                        }
                      );
                      if (existingQuestion) {
                        theItem = ko.toJS(item);
                        theItem.result = existingQuestion.result;
                        theItem.comment = existingQuestion.comment;
                      }
                    }
                    self.copyObjectIntoObservable(ko.toJS(theItem), result);
                    return result;
                  })
                );
                return controlAreaWithResult;
              });
            this.copyObjectIntoObservable(
              ko.toJS(controlAreas),
              this.data().visit().controlAreas
            );
          }
          if (this.visitType().notes().length) {
            this.copyObjectIntoObservable(
              ko.toJS(this.visitType().notes),
              this.data().visit().notes
            );
            ko.utils.arrayForEach(existingNotes, function (n) {
              var note = self
                .data()
                .visit()
                .notes()
                .find(function (x) {
                  return x.category() === n.category;
                });
              if (note) {
                note.comment(n.comment);
              }
            });
          }
        } else {
          if (this.visitType().controlAreas().length) {
            controlAreas = this.visitType()
              .controlAreas()
              .map(function (controlArea) {
                var controlAreaWithResult = new (self
                  .data()
                  .visit().controlAreas.type)();
                controlAreaWithResult.category(controlArea.category());
                controlAreaWithResult.questions(
                  controlArea.questions().map(function (item) {
                    var result = new controlAreaWithResult.questions.type();
                    self.copyObjectIntoObservable(ko.toJS(item), result);
                    return result;
                  })
                );
                return controlAreaWithResult;
              });
            this.copyObjectIntoObservable(
              ko.toJS(controlAreas),
              this.data().visit().controlAreas
            );
          }
          if (this.visitType().notes().length) {
            this.copyObjectIntoObservable(
              ko.toJS(this.visitType().notes),
              this.data().visit().notes
            );
          }
        }

        this.copyObjectIntoObservable(
          ko.toJS(new this.visitReportEmail.type()),
          this.visitReportEmail
        );
        this.visitReportEmail().subject(
          this.case().dnr() +
            (this.case().description() ? ": " + this.case().description() : "")
        );

        if (this.updateVisitSubscription) {
          this.updateVisitSubscription.dispose();
        }
        this.updateVisitSubscription = ko.computed(function () {
          ko.toJS(this.data().visit);
          var visit = prepareVisit.call(this);
          offlineCache.updateVisit(visit);
        }, this);
      },
      addDocument = function (file) {
        var self = this;
        return new Promise(function (resolve, reject) {
          var newfile = new (self.data().visit().documents.type)();
          newfile.filename(file.name);
          // Scale if image
          if (newfile.isImage()) {
            loadImage(
              file,
              function (image) {
                if (image.type === "error") {
                  reject();
                  return;
                }
                var imageData = image.toDataURL(
                  config.imageUpload.fileType,
                  config.imageUpload.quality
                );
                newfile.data(imageData);
                self.data().visit().documents.push(newfile);
                resolve();
              },
              {
                maxWidth: config.imageUpload.size,
                maxHeight: config.imageUpload.size,
                canvas: true,
                orientation: true,
              }
            );
          } else {
            // Just store data
            var fileReader = new FileReader();
            try {
              fileReader.onload = function (e) {
                newfile.data(e.target.result);
                self.data().visit().documents.push(newfile);
                resolve();
              };
              fileReader.readAsDataURL(file);
            } catch (ex) {
              // Cannot read file, fail
              reject(ex);
            }
          }
        });
      },
      prepareVisit = function () {
        var visit = ko.toJS(this.data().visit);
        visit.contacts = ko.toJS(this.data().visitContacts());
        visit.controlAreas = visit.controlAreasWithResults;
        visit.controlAreasWithResults = undefined;
        visit.notes = visit.notesWithComments;
        visit.notesWithComments = undefined;
        visit.isPlanned = visit.isPlanned === "1";
        visit.caseWorker = {};
        visit.caseWorker.id = parseInt(dataservice.login.loginInfo().id());
        visit.caseWorker.name = dataservice.login.loginInfo().displayName();
        visit.caseWorker.sign = dataservice.login.loginInfo().signature();
        visit.reportEmail = vm.global.isOnline()
          ? ko.toJS(this.visitReportEmail)
          : {};
        visit.caseId = this.case().id();
        visit.estateName = this.object().estateName();
        return visit;
      },
      previewVisit = function () {
        var visit = prepareVisit.call(this);
        return dataapi.visit.previewVisit(visit).then(function (result) {
          return result && result.data;
        });
      },
      uploadVisit = function () {
        var self = this;
        var caseId = this.case().id();
        var visit = prepareVisit.call(this);
        return dataapi.visit.uploadVisit(visit).then(function () {
          if (self.updateVisitSubscription) {
            self.updateVisitSubscription.dispose();
            self.updateVisitSubscription = null;
          }
          return (
            vm.global.isOnline() &&
            offlineCache.removeCachedVisit(self.case().dnr()).then(function () {
              return vm.global.isOnline()
                ? offlineCache.removeCaseFromCache(caseId)
                : undefined;
            })
          );
        });
      },
      removeCachedVisit = function () {
        return offlineCache.removeCachedVisit(this.case().dnr());
      },
      // Other
      unload = function () {
        if (this.updateVisitSubscription) {
          this.updateVisitSubscription.notifySubscribers();
          this.updateVisitSubscription.dispose();
          this.updateVisitSubscription = null;
        }
      },
      refresh = function () {
        var caseId = this.caseId(),
          visitTypeId = this.visitTypeId(),
          isPlanned = this.isPlanned();
        this.caseId(null);
        if (this.updateVisitSubscription) {
          this.updateVisitSubscription.dispose();
          this.updateVisitSubscription = null;
        }
        return this.uninit().then(
          init.bind(this, caseId, visitTypeId, isPlanned)
        );
      },
      init = function (caseId, visitTypeId, isPlanned, fromSearch, beforeInit) {
        if (!caseId || !visitTypeId) return Promise.reject();
        var self = this;

        return dataservice.Base.prototype.init
          .call(this, beforeInit)
          .then(function (initNeeded) {
            if (
              self.caseId() === caseId &&
              self.visitTypeId() === visitTypeId &&
              self.isPlanned() === isPlanned &&
              !fromSearch
            )
              return Promise.resolve(false);
            self.caseId(caseId);
            self.visitTypeId(visitTypeId);
            self.isPlanned(isPlanned);

            self.visitType(undefined);
            self
              .settings()
              .settings()
              .visitTypes()
              .some(function (visitType) {
                if (visitType.id() == visitTypeId) {
                  // Must be ==, visitTypeId is string
                  self.visitType(visitType);
                  return true;
                }
              });
            if (
              !self.visitType() ||
              (!self.visitType().canBeUnplanned() && !self.isPlanned()) ||
              !self.visitType().isActive()
            ) {
              throw new Error("Invalid/unknown visit type");
            }

            var result =
              (!initNeeded &&
                typeof beforeInit === "function" &&
                beforeInit()) ||
              Promise.resolve();
            result = result.then(function () {
              return getCase.call(self);
            });
            result = result.then(function () {
              return getObject.call(self);
            });
            result.then(function () {
              return offlineCache
                .getCachedVisit(self.case().dnr())
                .then(function (existingVisit) {
                  createNewVisit.call(self, existingVisit);
                  self.commit();
                  return true;
                });
            });
            return result;
          });
      };

    return {
      getDocumentUrl: getDocumentUrl,
      getDocument: getDocument,
      addDocument: addDocument,
      prepareVisit: prepareVisit,
      previewVisit: previewVisit,
      uploadVisit: uploadVisit,
      removeCachedVisit: removeCachedVisit,
      unload: unload,
      refresh: refresh,
      init: init,
    };
  })()
);

dataservice.visit = new dataservice.Visit();
