var dataservice = dataservice || {};

dataservice.Settings = function () {
    dataservice.Base.call(this);

    this.data().settings = dataservice.Base.data.settings;
}

dataservice.Settings.prototype = Object.create(dataservice.Base.prototype);
dataservice.Settings.prototype.constructor = dataservice.Settings;

utils.extend(dataservice.Settings.prototype, function () {
    var
        nextVisitTypeId = function () {
            var max = 0;
            this.data().settings().settings().visitTypes().forEach(function (visitType) {
                var id = visitType.id();
                if (id > max) {
                    max = id;
                }
            });
            return max + 1;
        },
        newVisitType = function () {
            var result = new (this.data().settings().settings().visitTypes.type);
            result.id(nextVisitTypeId.call(this));
            this.data().settings().settings().visitTypes.push(result);
            return result;
        },
        copyVisitType = function (visitType) {
            var result = new (this.data().settings().settings().visitTypes.type);
            utils.copyObjectIntoObservable(ko.toJS(visitType), result);
            result.id(nextVisitTypeId.call(this));
            result.description(result.description() && result.description() + " - Kopia");
            this.data().settings().settings().visitTypes.push(result);
            return result;
        },
        addReportTemplate = function (filename, filedata) {
            var reportTemplate = new (this.data().settings().settings().reportTemplates.type);
            reportTemplate.name(filename.replace(/(.*)\..*/, "$1"));
            reportTemplate.filename(filename);
            var base64 = filedata.split("base64,")[1]; // No content type etc, only the base64 chars
            reportTemplate.filedata(base64);
            this.data().settings().settings().reportTemplates.push(reportTemplate);
        },
        getReportTemplateUrl = function (name) {
            return dataapi.settings.getReportTemplateUrl(name);
        },
        updateSettings = function () {
            var data = ko.toJS(this.data().settings().settings);
            this.data().settings().settings().reportTemplates().forEach(function (reportTemplate) {
                reportTemplate.filedata(null);
            });
            return dataapi.settings.updateSettings(data);
        },
        init = function (beforeInit) {
            var self = this;
            return dataservice.Base.prototype.init.call(this, beforeInit)
                .then(function () {
                    // Clear report type of visit type if the report template gets deleted
                    self.data().settings().settings().reportTemplates.subscribe(function () {
                        self.data().settings().settings().visitTypes().forEach(function (visitType) {
                            var found = false;
                            self.data().settings().settings().reportTemplates().forEach(function (reportTemplate) {
                                if (visitType.reportType() === reportTemplate.name()) {
                                    found = true;
                                    return;
                                }
                            });
                            if (!found) {
                                visitType.reportType(undefined);
                            }
                        });
                    });

                    self.commit();
                });
        }

    return {
        newVisitType: newVisitType,
        copyVisitType: copyVisitType,
        addReportTemplate: addReportTemplate,
        getReportTemplateUrl: getReportTemplateUrl,
        updateSettings: updateSettings,
        init: init
    };
}());

dataservice.settings = new dataservice.Settings();