var model = model || {};

model.User = function () {
    this.id = ko.observable();
    this.username = ko.observable();
    this.password = ko.observable();
    this.displayName = ko.observable();
    this.isLoggedIn = ko.observable(false);
    this.signature = ko.observable();
    this.isAdmin = ko.observable(false);
    this.authenticationMethod = ko.observable();
    this.rememberMe = ko.observable(false);
    this.loggedOutManually = ko.observable(false);
}
