var vm = vm || {};

vm.VisitKontrollpunkter = function () {
    vm.VisitBase.call(this);

    this.controlArea = ko.observable();
    this.controlAreaPhrases = ko.observableArray();
}

vm.VisitKontrollpunkter.prototype = Object.create(vm.VisitBase.prototype);
vm.VisitKontrollpunkter.prototype.constructor = vm.VisitKontrollpunkter;

vm.VisitKontrollpunkter.prototype.setNotOkResult = function (item) {
    item.result(item.result() !== false ? false : undefined);
    item.isNoteExpanded(item.result() !== undefined);
}

vm.VisitKontrollpunkter.prototype.setOkResult = function (item) {
    item.result(item.result() !== true ? true : undefined);
    item.isNoteExpanded(item.result() !== undefined);
}

vm.VisitKontrollpunkter.prototype.init = function () {
    var self = this;
    return vm.VisitBase.prototype.init.apply(this, arguments)
        .then(function () {
            self.controlAreaPhrases.removeAll();
            var nrControlAreas = self.visit().controlAreas().length;
            for (var i = 0; i < nrControlAreas; i++) {
                self.controlAreaPhrases.push([]);
                var nrQuestions = self.visit().controlAreas()[i].questions().length;
                for (var j = 0; j < nrQuestions; j++) {
                    var note = { phrase: new ko.observable(), selectionStart: new ko.observable(), selectionEnd: new ko.observable() };
                    self.controlAreaPhrases()[i].push(note);
                    (function (phrase, caIndex, qIndex) {
                        phrase.phrase.subscribe(function (val) {
                            if (val) {
                                var comment = self.visit().controlAreas()[caIndex].questions()[qIndex].comment() || "";
                                var start = comment.slice(0, phrase.selectionStart());
                                var end = comment.slice(phrase.selectionEnd());
                                comment = (start && start[start.length - 1] !== "\n" ? start + "\n" : start) + phrase.phrase() + (end && end[0] !== "\n" ? "\n" + end : end);
                                self.visit().controlAreas()[caIndex].questions()[qIndex].comment(comment);
                            }
                        });
                    })(note, i, j);
                }

            }
        });
}

vm.visitKontrollpunkter = new vm.VisitKontrollpunkter();
