// TODO: Refactor this

var server = (function () {
    var
        online = ko.observable(true),
        forceOffline = ko.observable(false),
        isOnline = ko.pureComputed({
            read: function () {
                if (forceOffline()) return false;
                return online();
            },
            write: online
        }),
        awaitingPoll = false,
        timerId = 0,
        ping = function () {
            online(navigator.onLine);
        },
        startPolling = function () {
            if (timerId !== 0)
                clearInterval(timerId);
            timerId = setInterval(ping, config.serverPingOptions.interval);
            ping();
        },
        init = function () {
            forceOffline(window.location.search.indexOf("offline") !== -1); // If query param contains offline, force working offline
            startPolling();
        };

    return {
        isOnline: isOnline,
        forceOffline: forceOffline,
        init: init
    }
})();

var offlineCache = (function () {
    var
        getItem = function (key) {
            key = key.toString().toLowerCase();
            return localforage.getItem(key)
                .catch(function (err) {
                    utils.log("Error: localforage.getItem for key: " + key + " returned: ", err);
                });
        },
        setItem = function (key, value) {
            key = key.toString().toLowerCase();
            return localforage.setItem(key, value)
                .catch(function (err) {
                    utils.log("Error: localforage.setItem for key: " + key + " returned: ", err);
                });
        },
        removeItem = function (key) {
            key = key.toString().toLowerCase();
            return localforage.removeItem(key)
                .catch(function (err) {
                    utils.log("Error: localforage.removeItem for key: " + key + " returned: ", err);
                });
        },
        removeAll = function () {
            return localforage.clear();
        },

        cacheMap = [
            // Login
            {
                route: "api/user/info",
                store: "logininfo"
            },
            {
                route: "api/user/logout",
                store: "logout",
                afterPost: function (result) {
                    result.then(function () {
                        return getItem("logininfo")
                            .then(function (logininfo) {
                                logininfo.current.isLoggedIn = false;
                                logininfo.current.displayName = "";
                                logininfo.current.isAdmin = false;
                                return setItem("logininfo", logininfo)
                                    .then(function () { return result; });
                            });
                    });
                }
            },
            // Search
            {
                route: utils.getApiUrl("api/caseworkers", false),
                store: "caseworkers"
                //expires: 600000 //ms=10 minutes
            },
            {
                route: utils.getApiUrl("api/mapoptions", false),
                store: "mapoptions"
            },

            // Settings
            {
                route: utils.getApiUrl("api/settings", false),
                store: "settings"
            },
            {
                route: utils.getApiUrl("api/settings/settings", false),
                store: "settings/settings",
                sendWhenSyncing: true,
                beforePost: function (store) {
                    return getItem("settings")
                        .then(function (settings) {
                            // Update cached complete settings also
                            settings.current.settings = store.current;
                            return setItem("settings", settings);
                        });
                },
                afterPost: function (result, store, e) {
                    if (!e && store.synced) {
                        store.remove = true;
                    }
                }
            },

            // Visit
            {
                route: utils.getApiUrl("api/objects/:id", false),
                store: "object",
                storeKey: "id"
            },
            {
                route: utils.getApiUrl("api/cases/:id", false),
                store: "case",
                storeKey: "id",
                afterGet: function (result, store, e) {
                    if (e) return result;
                    result = result.then(function (casedata) {
                        if (!casedata || !casedata.data || !Array.isArray(casedata.data.events) || casedata.data.events.length === 0) return casedata;
                        var docPromise = Promise.resolve();
                        casedata.data.events.forEach(function (event) {
                            if (!Array.isArray(event.documents) || event.documents.length === 0) return;
                            event.documents.forEach(function (document) {
                                docPromise = docPromise.then(function () {
                                    return getItem(document.documentId).then(function (item) {
                                        document.file = item && item.document_base64 && item.document_base64.current;
                                    });
                                });
                            });
                        });
                        return docPromise.then(function () { return casedata; });
                    });
                    return result;
                }
            },
            {
                route: utils.getApiUrl("api/documents/:id/base64/offline", false),
                store: "document_base64",
                storeKey: "id"
            },
            {
                route: utils.getApiUrl("api/visitreports", false),
                store: "visitreports",
                storeKey: function (data) {
                    return data.dnr;
                },
                sendWhenSyncing: true,
                afterPost: function (result, store, e) {
                    if (!e && store.visitreports.synced) {
                        store.remove = true;
                    }
                }
            }
        ],

        getCachedCases = function () {
            var result = [];
            return new Promise(function (resolve) {
                var promise = Promise.resolve();
                localforage.iterate(function (store) {
                    if (store && store.case) {
                        var caseItem = undefined;
                        promise = promise.then(function () {
                            return getItem(store.case.current.objectId).then(function (object) {
                                return getItem(store.case.current.dnr).then(function (visit) {
                                    var theVisit = visit && visit.visitreports && visit.visitreports.current || null;
                                    if (store.madeAvailableOffline || theVisit) {
                                        caseItem = {
                                            "case": store.case.current,
                                            plannedVisits: store.plannedVisits,
                                            controlResponsible: store.controlResponsible,
                                            object: object.object.current,
                                            hasVisit: visit ? true : false,
                                            estateId: store.estateId,
                                            geoEstates: store.geoEstates,
                                            visit: theVisit,
                                            isCached: store.madeAvailableOffline,
                                        };
                                        result.push(caseItem);
                                    }
                                });
                            });
                        });
                    }
                })
                .then(function () {
                    promise = promise.then(function () { resolve(result); });
                });
            });
        },

        updateVisit = function (visit) {
            if (!visit || !visit.dnr) return Promise.reject();
            var v = JSON.parse(ko.toJSON(visit));

            return getItem(visit.dnr).then(function (x) {
                x = x || {};
                x.visitreports = x.visitreports || {};
                x.visitreports.synced = true;
                x.visitreports.completed = false;
                x.visitreports.current = v;
                return setItem(visit.dnr, x);
            });
        },

        getCachedVisit = function (dnr) {
            if (!dnr) return Promise.reject();
            return getItem(dnr).then(function (x) {
                return x && x.visitreports && x.visitreports.current || null;
            });
        },

        removeCachedVisit = function (dnr) {
            if (!dnr) return Promise.reject();
            return removeItem(dnr);
        },

        removeCaseFromCache = function (caseId, removeVisit) {
            var remove = false,
                visit = undefined,
                documentsToRemove = undefined;
            return localforage.iterate(function (store, key) {
                    // eslint-disable-next-line
                    if (key == caseId && store && store.case && !store.object) { // Must be ==
                        remove = true;
                        visit = store.case.current.dnr;
                        documentsToRemove = store.case.current.events.reduce(function (a, c) {
                            return a.concat(c.documents
                                .filter(function (d) { return d.hasDocument; })
                                .map(function (d) { return d.documentId; }));
                        }, []);
                    }
                })
                .then(function () {
                    if (remove) {
                        var removeDocumentsPromise = Promise.resolve();
                        documentsToRemove.forEach(function (id) {
                            removeDocumentsPromise = removeDocumentsPromise.then(function () {
                                return getItem(id).then(function (docItem) {
                                    if (!docItem) return;
                                    if (docItem.case || docItem.object) {
                                        docItem.document_base64 = undefined;
                                        return setItem(id, docItem);
                                    }
                                    return removeItem(id);
                                });
                            });
                        });
                        return removeDocumentsPromise.then(function () {
                            return removeItem(caseId).then(function () {
                                return visit && removeVisit ? removeItem(visit) : undefined;
                            });
                        });
                    }
                    return undefined;
                });
        },

        makeSearchHitAvailableOffline = function (caseId, plannedVisits, controlResponsible, estateId, geoEstates) {
            return getItem(caseId)
                .then(function (caseStore) {
                    if (!caseStore) caseStore = {};
                    caseStore.plannedVisits = plannedVisits;
                    caseStore.controlResponsible = controlResponsible;
                    caseStore.estateId = estateId;
                    caseStore.geoEstates = geoEstates;
                    caseStore.madeAvailableOffline = true;
                    return setItem(caseId, caseStore);
                });
        },

        hasUnsyncedChanges = ko.observable(false),
        setHasUnsyncedChanges = function () {
            return localforage.iterate(function (store, key) {
                    var unsynced = utils.firstOrDefault(cacheMap, function (cacheItem) {
                        return cacheItem.store === key && cacheItem.sendWhenSyncing && store.synced === false;
                    });
                    if (unsynced) return true;
                    // Not a common store, maybe it is an object store, check entries in it
                    unsynced = utils.firstOrDefault(store, function (storeItem, storeProp) {
                        var cacheMapEntry = utils.firstOrDefault(cacheMap, function (cacheItem) {
                            return cacheItem.store === storeProp;
                        });
                        return cacheMapEntry && cacheMapEntry.sendWhenSyncing && storeItem.synced === false;
                    });
                    if (unsynced) return true;
                    return undefined;
                })
                .then(function (unsynced) {
                    hasUnsyncedChanges(unsynced);
                });
        },

        getUnsyncedChanges = function () {
            var result = [];
            return localforage.iterate(function (store, key) {
                    var next = ko.utils.arrayFirst(cacheMap, function (cacheItem) {
                        if (cacheItem.store === key && cacheItem.sendWhenSyncing && store.synced === false) {
                            result.push({ url: cacheItem.route, data: store.current });
                            return true;
                        }
                    });
                    if (next) return;
                    // Not a common store, maybe it is an object store, check entries in it
                    ko.utils.objectForEach(store, function (storeProp, storeItem) {
                        utils.firstOrDefault(cacheMap, function (cacheItem) {
                            if (cacheItem.store === storeProp && cacheItem.sendWhenSyncing && storeItem.synced === false) {
                                var url = cacheItem.route.replace(/(:[\w\d]+)/, key);
                                result.push({ url: url, data: storeItem.current });
                            }
                        });
                    });
                })
                .then(function () {
                    return result;
                });
        },

        syncChanges = function (step, total) {
            var sendChanges = function (change) {
                step(step() + 1);
                return utils.query(change.url, { method: "POST", data: JSON.stringify(change.data) });
            };

            return getUnsyncedChanges()
                .then(function (unsyncedChanges) {
                    if (!unsyncedChanges || unsyncedChanges.length === 0) return Promise.reject();
                    total(unsyncedChanges.length);
                    step(-1);

                    var change = unsyncedChanges[0];
                    var result = sendChanges(change);
                    for (var i = 1; i < unsyncedChanges.length; i++) {
                        result = result.then(sendChanges.bind(null, unsyncedChanges[i]));
                    }
                    result.then(function () {
                        step(total());
                    });
                    return result;
                });
        },

        getCacheMapEntryForUrl = function (url) {
            return utils.firstOrDefault(cacheMap, function (item) {
                var route = item.route.replace(/:([\w\d]+)/g, "([^/\\?&]+)") + "(?:\\?.*)?";
                var routeRegExp = new RegExp("^" + route + "$");
                return routeRegExp.test(url);
            });
        },

        getParameterValues = function (route, url) {
            // Get parameter names from route
            var paramNamesRegexp = /:([\w\d]+)/g,
                paramNamesMatch,
                paramNames = [];
            while ((paramNamesMatch = paramNamesRegexp.exec(route)) !== null) {
                paramNames.push(paramNamesMatch[1]);
            }

            // Get parameter values from url
            route = route.replace(/:([\w\d]+)/g, "([^/\\?&]+)") + "(?:\\?.*)?";
            var paramValuesRegex = new RegExp("^" + route + "$"),
                paramValuesMatch = paramValuesRegex.exec(url),
                params = {};
            if (paramValuesMatch) {
                paramValuesMatch.shift(); // Remove first match (=whole url)
                ko.utils.arrayForEach(paramValuesMatch, function (value, index) {
                    params[paramNames[index]] = value;
                });
            }

            return params;
        },

        query = function (url, options) {
            // Get entry from cache map
            var cacheMapEntry = getCacheMapEntryForUrl(url);
            // If not found then this is not a call we cache, just send it
            if (!cacheMapEntry) {
                if (!server.isOnline()) // If offline, fail directly
                    return Promise.resolve({ status: 503 });
                return utils.ajax(url, options);
            }

            // Get parameter values from url
            var params = getParameterValues(cacheMapEntry.route, url);

            // Construct key to get from cache
            var storeKey;
            if (Array.isArray(cacheMapEntry.storeKey)) {
                storeKey = cacheMapEntry.storeKey.reduce(function (p, c) { return p + "_" + params[c]; }, "").substring(1);
            } else
                storeKey = params[cacheMapEntry.storeKey] || cacheMapEntry.storeKey || cacheMapEntry.store;
            if (typeof storeKey === "function")
                storeKey = storeKey(JSON.parse(options.data));

            // Get store and value from cache if any, else create them
            return getItem(storeKey)
                .then(function (store) {
                    var object;
                    if (cacheMapEntry.storeKey) { // Use key as store
                        if (!store) {
                            store = {};
                        }
                        if (!store[cacheMapEntry.store]) {
                            store[cacheMapEntry.store] = {
                                original: cacheMapEntry.storeElementType ? new (cacheMapEntry.storeElementType) : null,
                                current: cacheMapEntry.storeElementType ? new (cacheMapEntry.storeElementType) : null,
                                date: null,
                                synced: null
                            };
                        }
                        object = store[cacheMapEntry.store];
                    } else {
                        if (!store) {
                            store = {
                                original: cacheMapEntry.storeElementType ? new (cacheMapEntry.storeElementType) : null,
                                current: cacheMapEntry.storeElementType ? new (cacheMapEntry.storeElementType) : null,
                                date: null,
                                synced: null
                            };
                        }
                        object = store;
                    }

                    var requestResult = null;
                    var method = options.method.toUpperCase();
                    switch (method) {
                    case "GET":
                        var doGet = function () {
                            var result = undefined;
                            var now = (new Date).getTime();
                            // Return cached data if not expired and allowed, but not cached 404s and not when in debug
                            if (!utils.debug && cacheMapEntry.expires && object.date && now - object.date < cacheMapEntry.expires) {
                                if (object.current !== null && object.current !== 404)
                                    result = Promise.resolve({ data: object.current, status: 200 });
                            }
                            // Respond with data in cache when offline or fail request if data not present
                            if (!result && !server.isOnline()) {
                                if (object.current !== null && object.current !== 404) // Return cached data
                                    result = Promise.resolve({ data: object.current, status: 200 });
                                else if (object.current === 404) // Return cached 404
                                    result = Promise.reject({ status: 404 });
                                else // Result not in cache, fail request
                                    result = Promise.reject({ status: 503 });
                            }
                            // Online and no data yet, do the get
                            if (!result) {
                                result = utils.ajax(url, options)
                                    .then(
                                        function (response) {
                                            // Save response in cache if no unsynced changes
                                            if (object.synced !== false) {
                                                object.original = cacheMapEntry.sendOriginal ? response.data : null;
                                                object.current = typeof response.data === "boolean" ? response.data : (response.data || null);
                                                object.date = (new Date).getTime();
                                                object.synced = true;
                                            }
                                            return Promise.resolve({ data: object.current, status: response.status });
                                        },
                                        function (response) {
                                            // Check for real failed response
                                            if (response && response.status === 404) {
                                                // Plain not found, we store these too
                                                // Save response in cache if no unsynced changes
                                                if (object.synced !== false) {
                                                    object.original = cacheMapEntry.sendOriginal ? 404 : null;;
                                                    object.current = 404;
                                                    object.synced = true;
                                                }
                                                if (object.current !== 404)
                                                    return Promise.resolve({ data: object.current, status: 200 });
                                                else
                                                    return Promise.reject(response);
                                            } else if (response && response.status >= 300) { // use this when using fiddler: && response.status <= 500) {
                                                // Server error
                                                return Promise.reject(response);
                                                //} else if (response.status === 0 && response.readyState === 0) {
                                                //    // Error caused by client
                                                //    return Promise.reject({ status: 500 }, "500", "Error");
                                            } else {
                                                // Not a server response, we went offline after placing the ajax call
                                                server.isOnline(false);
                                                // Respond with data in cache or fail request if data not present
                                                if (object.current !== null && object.current !== 404) // Return cached data
                                                    return Promise.resolve({ data: object.current, status: 200 });
                                                else if (object.current === 404) // Return cached 404
                                                    return Promise.reject({ status: 404 });
                                                else // Result not in cache, fail request
                                                    return Promise.reject({ status: 503 });
                                            }
                                        });
                            }
                            return result;
                        }

                        // Action before GET
                        if (cacheMapEntry.beforeGet) {
                            requestResult = cacheMapEntry.beforeGet(store);
                        }
                        // GET
                        if (requestResult)
                            requestResult = requestResult.then(doGet);
                        else
                            requestResult = doGet();
                        // Action after GET
                        if (cacheMapEntry.afterGet) {
                            requestResult = requestResult.then(cacheMapEntry.afterGet.bind(null, requestResult, store, null))
                                .catch(function (e) {
                                    var afterResult = cacheMapEntry.afterGet(requestResult, store, e);
                                    if (afterResult === undefined) throw e;
                                    return afterResult;
                                });
                        }

                        break;
                    case "POST":
                    case "PUT":
                        object.current = options.data ? JSON.parse(options.data) : null;
                        var doPostPut = function () {
                            var result;
                            if (!server.isOnline()) {
                                // We're offline, save data in cache and answer ok
                                object.synced = false;
                                result = Promise.resolve({ data: object.current, status: 200 });
                            } else {
                                if (cacheMapEntry.sendOriginal) {
                                    var dataToSend = { original: object.original, current: object.current };
                                    options.data = JSON.stringify(dataToSend);
                                }
                                result = utils.ajax(url, options)
                                    .then(
                                        function (response) {
                                            // Save response in cache if object
                                            if (typeof response === "object") {
                                                object.original = cacheMapEntry.sendOriginal ? response : null;
                                                object.current = response || null;
                                            }
                                            object.synced = true;
                                            return Promise.resolve(response);
                                        },
                                        function (response) {
                                            if (response && response.status >= 300) { // use this when using fiddler: && response.status <= 500) {
                                                // Real server error
                                                return Promise.reject(response);
                                                //} else if (response.status === 0 && response.readyState === 0) {
                                                //    // Error caused by client
                                                //    return Promise.reject({ status: 500 }, "500", "Error");
                                            } else {
                                                // We went offline after placing the ajax call, save data in cache and answer ok
                                                server.isOnline(false);
                                                object.synced = false;
                                                return Promise.resolve({ data: object.current, status: 200});
                                            }
                                        });
                            }
                            return result;
                        }

                        // Action before POST/PUT
                        if (cacheMapEntry.beforePost && method === "POST") {
                            requestResult = cacheMapEntry.beforePost(store);
                        } else if (cacheMapEntry.beforePut && method === "PUT") {
                            requestResult = cacheMapEntry.beforePut(store);
                        }
                        // POST/PUT
                        if (requestResult)
                            requestResult = requestResult.then(doPostPut);
                        else
                            requestResult = doPostPut();
                        // Action after POST/PUT
                        if (cacheMapEntry.afterPost && method === "POST") {
                            requestResult = requestResult.then(cacheMapEntry.afterPost.bind(null, requestResult, store, null))
                                .catch(function (e) {
                                    var afterResult = cacheMapEntry.afterPost(requestResult, store, e);
                                    if (afterResult === undefined) throw e;
                                    return afterResult;
                                });
                        } else if (cacheMapEntry.afterPut && method === "PUT") {
                            requestResult = requestResult.then(cacheMapEntry.afterPut.bind(null, requestResult, store, null))
                                .catch(function (e) {
                                    var afterResult = cacheMapEntry.afterPut(requestResult, store, e);
                                    if (afterResult === undefined) throw e;
                                    return afterResult;
                                });
                        }
                        break;
                    case "DELETE":
                        // Not doing any DELETE calls
                        requestResult = Promise.reject();
                        break;
                    }

                    var done = function () {
                        var setItemDone = function () {
                            setHasUnsyncedChanges();
                            return requestResult;
                        }
                        // If after get/post/put returns remove true, remove store from cache
                        return (store.remove === true ? removeItem(storeKey) : setItem(storeKey, store)).then(setItemDone, setItemDone);
                    };
                    return requestResult.then(done, done);
                });
        },


        init = function () {
            localforage.config({
                name: "Platsbesok"
            });
        };

    return {
        init: init,
        removeCaseFromCache: removeCaseFromCache,
        makeSearchHitAvailableOffline: makeSearchHitAvailableOffline,
        getCachedCases: getCachedCases,
        getCachedVisit: getCachedVisit,
        removeAll: removeAll,
        hasUnsyncedChanges: hasUnsyncedChanges,
        getUnsyncedChanges: getUnsyncedChanges,
        syncChanges: syncChanges,
        query: query,
        updateVisit: updateVisit,
        removeCachedVisit: removeCachedVisit,
    };
})();