var dataapi = dataapi || {};

dataapi.settings = {
    // Get
    getSettings: function () {
        return utils.query("api/settings");
    },
    getReportTemplateUrl: function (name) {
        return utils.getApiUrl("api/settings/reporttemplates/" + name, true);
    },
    // Create/update
    updateSettings: function (data) {
        var dataJson = JSON.stringify(data);
        return utils.query("api/settings/settings", {
            method: "POST",
            data: dataJson
        });
    }
}