var dataapi = dataapi || {};

dataapi.visit = {
  // Get
  getObject: function (id) {
    return utils.query("api/objects/" + id);
  },
  getCase: function (id) {
    return utils.query("api/cases/" + id);
  },
  getDocument: function (id) {
    return utils.query("api/documents/" + id + "/base64");
  },
  getDocumentOffline: function (id) {
    return utils.query("api/documents/" + id + "/base64/offline");
  },
  getDocumentUrl: function (id) {
    return utils.getApiUrl("api/documents/" + id, true);
  },

  // Create/update
  uploadVisit: function (data) {
    var dataJson = JSON.stringify(data);
    return utils.query("api/visitreports", {
      method: "POST",
      data: dataJson,
    });
  },
  previewVisit: function (data) {
    var dataJson = JSON.stringify(data);
    return utils.query("api/visitreportpdf", {
      method: "POST",
      data: dataJson,
    });
  },
};
