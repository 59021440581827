var application = (function () {

    var
        initTimer,
        doInits = function () {
            var inits = [
                server,
                navigation,
                offlineCache,
                vm.global
            ];
            var initPromise = Promise.resolve();
            inits.forEach(function (init) {
                initPromise = initPromise.then(function () {
                    return typeof init.init === "function" && init.init.call(init);
                });
            });
            return initPromise;
        },

        initialize = function () {
            utils.log("Run");
            clearTimeout(initTimer);

            document.title = config.applicationName;

            // Stuff for mobile devices
            utils.removeHoverEffects();
            utils.noChosenForMobile();

            // Set locale to swedish for components
            ko.validation.locale("sv-SE");
            moment.locale("sv");

            // Tekis Components
            tekis.components.parentVmType(vm.Base);

            // Init stuff and start ui and navigation
            doInits().then(function () {
                ko.applyBindings(vm.global);
                vm.login.init().then(function () { navigation.run(); });
            });
        },

        run = function () {
            initialize();
        };

    return {
        run: run
    };

})();

application.run();

if ("serviceWorker" in navigator) {
    window.addEventListener("load", function () {
        navigator.serviceWorker.register("./sw.js").then(function (registration) {
            console.log("ServiceWorker registration successful with scope: ", registration.scope);

            function awaitStateChange() {
                registration.installing.addEventListener("statechange", function () {
                    if (this.state === "installed") vm.global.serviceWorkerRegistration(registration);
                });
            }

            if (registration.waiting) vm.global.serviceWorkerRegistration(registration);
            if (registration.installing) awaitStateChange();
            registration.addEventListener("updatefound", awaitStateChange);
        }, function (err) {
            console.log("ServiceWorker registration failed: ", err);
        });
    });

    var refreshing;
    navigator.serviceWorker.addEventListener("controllerchange",
        function () {
            if (refreshing) return;
            refreshing = true;
            window.location.reload();
        }
    );
} else {
    throw new Error("Browser does not support service workers.");
}