var vm = vm || {};

vm.VisitNotering = function () {
    vm.VisitBase.call(this);

    this.selectedPhrases = ko.observableArray();
}

vm.VisitNotering.prototype = Object.create(vm.VisitBase.prototype);
vm.VisitNotering.prototype.constructor = vm.VisitNotering;

vm.VisitNotering.prototype.init = function () {
    var self = this;
    return vm.VisitBase.prototype.init.apply(this, arguments)
        .then(function () {
            self.selectedPhrases.removeAll();
            var nrNotes = self.visit().notes().length;
            for (var i = 0; i < nrNotes; i++) {
                var note = { phrase: new ko.observable(), selectionStart: new ko.observable(), selectionEnd: new ko.observable() };
                self.selectedPhrases.push(note);
                (function (phrase, index) {
                    phrase.phrase.subscribe(function (val) {
                        if (val) {
                            var comment = self.visit().notes()[index].comment() || "";
                            var start = comment.slice(0, phrase.selectionStart());
                            var end = comment.slice(phrase.selectionEnd());
                            comment = (start && start[start.length - 1] !== "\n" ? start + "\n" : start) + phrase.phrase() + (end && end[0] !== "\n" ? "\n" + end : end);
                            self.visit().notes()[index].comment(comment);
                        }
                    });
                })(note, i);
            }
        });
}

vm.visitNotering = new vm.VisitNotering();
