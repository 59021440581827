var vm = vm || {};

vm.Base = function () {
    this.ready = ko.observable();
    this.alwaysEditing = ko.observable(false);
    var editing = ko.observable();
    this.editing = ko.pureComputed({
        read: function () { return this.alwaysEditing() || editing(); },
        write: function (val) { editing(val); }
    }, this);
}

vm.Base.prototype.edit = function () {
    this.editing(true);
}

vm.Base.prototype.rollback = function () {
    this.editing(false);
    return Promise.resolve();
}

vm.Base.prototype.commit = function () {
    this.editing(false);
    return Promise.resolve();
}
