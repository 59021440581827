var vm = vm || {};

vm.ImageViewer = function () {
    this.visible = ko.observable();
    this.files = ko.observableArray();
    this.currentFile = ko.observable();

    this.prevEnabled = ko.pureComputed(function () {
        return this.currentFile() > 0;
    }, this);
    this.nextEnabled = ko.pureComputed(function () {
        return this.currentFile() < this.files().length - 1;
    }, this);
}

// files = url[] or url or {url,description}[] or {url,description} of images to show in viewer
vm.ImageViewer.prototype.show = function (files, startIndex) {
    this.currentFile(startIndex || 0);
    files = files || [];
    files = Array.isArray(files) ? files : [files];
    for (var i in files) {
        if (typeof files[i] !== "object") {
            files[i] = { url: files[i] };
        }
    }
    this.files(files);
    this.visible(true);
}

vm.ImageViewer.prototype.hide = function () {
    this.visible(false);
}

vm.ImageViewer.prototype.prev = function () {
    this.currentFile(this.currentFile() - 1);    
}

vm.ImageViewer.prototype.next = function () {
    this.currentFile(this.currentFile() + 1);
}

vm.imageViewer = new vm.ImageViewer();